import React from "react";
import ReactMarkdown, { Components } from "react-markdown";
import rehypeRaw from "rehype-raw";
import remarkGfm from "remark-gfm"; // Import the remark-gfm plugin
import { useTheme } from "./ThemeContext";

interface IMarkdownProps {
  data: string; // The markdown content as a string
  markdownClassesProps?: string;
  themeOverride?: "light" | "dark";
}

type ThemeType = "light" | "dark";

const Markdown: React.FC<IMarkdownProps> = ({
  data,
  markdownClassesProps,
  themeOverride,
}) => {
  let currentTheme: ThemeType = themeOverride || "light";

  try {
    const themeContext = useTheme();
    if (!themeOverride && themeContext?.theme) {
      currentTheme = themeContext.theme as ThemeType;
    }
  } catch (error) {
    // If useTheme fails (outside provider), keep the default light theme
  }

  // Define custom renderers for Markdown components, including table elements
  const markdownRenderers: Components = {
    h1: ({ node, ...props }) => (
      <h1 className="text-4xl font-semibold" {...props}>
        {props.children || <span className="sr-only">Untitled</span>}
      </h1>
    ),
    h2: ({ node, ...props }) => (
      <h2 className="text-2xl font-semibold" {...props}>
        {props.children || <span className="sr-only">Untitled</span>}
      </h2>
    ),
    h3: ({ node, ...props }) => (
      <h3 className="text-xl font-bold" {...props}>
        {props.children || <span className="sr-only">Untitled</span>}
      </h3>
    ),
    h4: ({ node, ...props }) => (
      <h4 className="text-lg font-bold underline" {...props}>
        {props.children || <span className="sr-only">Untitled</span>}
      </h4>
    ),
    h5: ({ node, ...props }) => (
      <h5 className="text-base font-bold underline" {...props}>
        {props.children || <span className="sr-only">Untitled</span>}
      </h5>
    ),
    p: ({ node, ...props }) => (
      <p className="text-base font-light mb-4" {...props} />
    ),
    ul: ({ node, ...props }) => <ul className="mb-4" {...props} />,
    li: ({ node, ...props }) => (
      <li className="list-disc ml-6 font-thin py-2" {...props} />
    ),
    hr: ({ node, ...props }) => (
      <hr className="border-t-[1px] border-black opacity-30 my-4" {...props} />
    ), // Custom divider line
    strong: ({ node, ...props }) => (
      <strong className="font-semibold" {...props} />
    ),
    em: ({ node, ...props }) => <em className="italic" {...props} />,
    del: ({ node, ...props }) => <del className="line-through" {...props} />,

    // Custom table renderer with solid lines and shaded headers
    table: ({ node, ...props }) => (
      <div
        className={`p-0.5 rounded-lg mb-6 ${
          currentTheme === "dark"
            ? "bg-brand-cream/50"
            : "bg-brand-green-dark/50"
        }`}
      >
        <table
          className={`w-full border-collapse ${
            currentTheme === "dark"
              ? "border-2 border-brand-cream/50 bg-brand-green"
              : "border-2 border-brand-green-dark/50 bg-brand-cream"
          } rounded-lg overflow-hidden`}
          {...props}
        />
      </div>
    ),
    thead: ({ node, ...props }) => (
      <thead
        className={`${
          currentTheme === "dark" ? "bg-brand-cream/10" : "bg-brand-cream/20"
        }`}
        {...props}
      />
    ),
    th: ({ node, ...props }) => (
      <th
        className={`px-4 py-2 text-left font-semibold text-sm ${
          currentTheme === "dark"
            ? "text-brand-cream bg-brand-cream/10 border border-brand-cream/50"
            : "text-brand-green-dark bg-brand-cream/30 border border-brand-green-dark/50"
        }`}
        {...props}
      />
    ),
    tbody: ({ node, ...props }) => (
      <tbody
        className={`divide-y ${
          currentTheme === "dark"
            ? "divide-brand-cream/50"
            : "divide-brand-green-dark/50"
        }`}
        {...props}
      />
    ),
    td: ({ node, ...props }) => (
      <td
        className={`px-4 py-2 text-sm ${
          currentTheme === "dark"
            ? "text-brand-cream/90 border border-brand-cream/50"
            : "text-brand-green-dark/80 border border-brand-green-dark/50"
        }`}
        {...props}
      />
    ),
    pre: ({ node, inline, className, children, ...props }: any) => {
      return (
        <Markdown
          data={children?.props?.children}
          markdownClassesProps={"text-brand-green-dark hover:text-green-dark "}
        />
      );
    },
  };

  // Determine the styling class based on the current theme
  const markdownClasses =
    currentTheme === "dark" ? "text-brand-cream" : "text-brand-green";

  return (
    <ReactMarkdown
      className={markdownClassesProps || markdownClasses}
      components={markdownRenderers}
      rehypePlugins={[rehypeRaw]}
      remarkPlugins={[remarkGfm]} // Add remark-gfm to support tables
    >
      {data}
    </ReactMarkdown>
  );
};

export default Markdown;
