import React from "react";
import { Routes, Route, useLocation } from "react-router-dom";
import { Toaster } from "react-hot-toast";
import SignUp from "./components/Pages/SignUp/Login";
import Dashboard from "./components/Pages/Dashboard/Dashboard";
import MenuBar from "./components/Menu/MenuBar";
import Chat from "./components/Pages/chat/Chat";
import Admin from "./components/Pages/admin";
import AssistantForm from "./components/Pages/AssistantForm";
import UserForm from "./components/Pages/UserForm";
import UpdateAssistant from "./components/Pages/UpdateAssistant";
import AllUsers from "./components/Pages/AllUsers";
import Resources from "./components/Pages/Resources/Resources";
import PromptForm from "./components/Pages/PromptForm";
import UserChats from "./components/Pages/UserChats";
import UpdateUserForm from "./components/Pages/updateUserForm";
import UsersList from "./components/Pages/UsersList";
import ConfigPage from "./components/Pages/config";
import SignUpPage from "./components/Pages/SignUp/SignUp";
import OnboardingPage from "./components/Pages/Onboarding/OnboardingPage";
import AdminOnboarding from "./components/Pages/admin/onboarding/Onboarding";
import AdminVideos from "./components/Pages/admin/videos/AdminVideos";
import Subscription from "./components/Pages/subscribe/Subscription";
import Actions from "./components/Pages/Actions/Actions";
import Community from "./components/Pages/Community/Community";
import BusinessModelPage from "./components/Pages/BusinessModel/BusinessModelPage";
import ImageLibrary from "./components/Pages/Dashboard/ImageLibrary";
import AdminBusinessModelPage from "./components/Pages/admin/onboarding/AdminBusinessModel";

const AppContent: React.FC = () => {
  const location = useLocation();
  const hideMenuBarPaths = ["/userform"];

  const shouldHideMenuBar = hideMenuBarPaths.some((path) =>
    location.pathname.startsWith(path)
  );

  const renderMenuBar = () => {
    if (shouldHideMenuBar) return null;
    return <MenuBar />;
  };

  return (
    <div className="relative min-h-screen">
      <Toaster position="top-center" />
      {renderMenuBar()}
      <Routes>
        <Route path="/" element={<SignUp />} />
        <Route path="/onboarding" element={<OnboardingPage />} />
        <Route path="/sign-up" element={<SignUpPage />} />
        <Route path="/resources" element={<Resources />} />
        <Route path="/dashboard" element={<Dashboard />} />
        <Route path="/actions" element={<Actions />} />
        <Route path="/community" element={<Community />} />
        <Route path="/business-model" element={<BusinessModelPage />} />
        <Route path="/assistant/:assistantId" element={<Chat />} />
        <Route path="/chat" element={<Chat />} />
        <Route
          path="/assistantform"
          element={<AssistantForm isUpdate={false} />}
        />
        <Route path="/updateAssistant" element={<UpdateAssistant />} />
        <Route path="/image-library" element={<ImageLibrary />} />
        <Route
          path="/updateAssistant/:_id"
          element={<AssistantForm isUpdate={true} />}
        />
        <Route path="/admin" element={<Admin />} />
        <Route
          path="admin/business-model/:userId"
          element={<AdminBusinessModelPage />}
        />
        <Route path="/admin/onboarding" element={<AdminOnboarding />} />
        <Route path="/admin/videos" element={<AdminVideos />} />
        <Route path="/userform" element={<UserForm />} />
        <Route path="/updateUser/:id" element={<UpdateUserForm />} />
        <Route path="/allUsers" element={<AllUsers />} />
        <Route path="/subscription" element={<Subscription />} />
        <Route path="/create-prompt" element={<PromptForm />} />
        <Route path="/config" element={<ConfigPage />} />
        <Route path="/get-chats" element={<UsersList />} />
        <Route path="/get-chats/:userId" element={<UserChats />} />
      </Routes>
    </div>
  );
};

export default AppContent;
