import { adminService } from "./adminService";
import { assistantService } from "./assistantService";
import { chatService } from "./chatService";
import { actionsService } from "./actionsService";
export const service = {
	assistantService: assistantService,
	adminService: adminService,
	chatService: chatService,
	actionsService: actionsService,
};
