import { IAssistant } from "../types/interfaces";

export const assistantService = {
	/**
	 * Retrieves all assistants from the API.
	 *
	 * @returns {Promise<IAssistant[]>} A promise that resolves to an array of IAssistant objects.
	 */
	getAssistants: async (token: string | null): Promise<IAssistant[]> => {
		const response = await fetch(
			`${process.env.REACT_APP_API_BASE_URL}/api/assistants/get-all-assistants`,
			{
				method: "GET",
				headers: {
					"Content-Type": "application/json",
					Authorization: `Bearer ${token}`,
				},
			},
		);
		return response.json();
	},
	getAssistant: async (token: string | null, _id: string) => {
		const response = await fetch(
			`${process.env.REACT_APP_API_BASE_URL}/api/assistants/get-assistant/${_id}`,
			{
				method: "GET",
				headers: {
					Authorization: `Bearer ${token}`,
				},
			},
		);
		if (!response.ok) {
			throw new Error("Failed to fetch assistant");
		}
		return response.json();
	},
	createAssistant: async (
		token: string | null,
		assistantData: Omit<IAssistant, "_id"> & { basePrompt: string, createdBy?: string, user?: string },
	) => {
		const response = await fetch(
			`${process.env.REACT_APP_API_BASE_URL}/api/assistants/create-assistant`,
			{
				method: "POST",
				headers: {
					"Content-Type": "application/json",
					Authorization: `Bearer ${token}`,
				},
				body: JSON.stringify(assistantData),
			},
		);
		if (!response.ok) {
			throw new Error("Failed to create assistant");
		}
		return response.json();
	},
	updateAssistant: async (
		token: string | null,
		assistantData: Partial<IAssistant> & { 
			basePrompt: string, 
			prompts?: any[],
			user?: string 
		},
		assistantId: string,
	) => {
		console.log("Using assistantService.updateAssistant instead of adminService");
		
		const response = await fetch(
			`${process.env.REACT_APP_API_BASE_URL}/api/assistants/update-assistant/${assistantId}`,
			{
				method: "PUT",
				headers: {
					"Content-Type": "application/json",
					Authorization: `Bearer ${token}`,
				},
				body: JSON.stringify(assistantData),
			},
		);
		
		if (!response.ok) {
			const errorText = await response.text();
			console.error("Failed to update assistant:", response.status, errorText);
			throw new Error(`Failed to update assistant: ${response.status} ${errorText}`);
		}
		return response.json();
	},
	deleteAssistant: async (
		token: string | null,
		assistantId: string,
	) => {
		console.log("Attempting to delete assistant:", assistantId);
		
		const response = await fetch(
			`${process.env.REACT_APP_API_BASE_URL}/api/assistants/delete-assistant/${assistantId}`,
			{
				method: "DELETE",
				headers: {
					"Content-Type": "application/json",
					Authorization: `Bearer ${token}`,
				},
			},
		);
		
		if (!response.ok) {
			const errorText = await response.text();
			console.error("Failed to delete assistant:", response.status, errorText);
			throw new Error(`Failed to delete assistant: ${response.status} ${errorText}`);
		}
		return response.json();
	},
};
