import { useUser } from "@clerk/clerk-react";
import { useLocation, useNavigate } from "react-router-dom";
import { UserService } from "../services/userService";
import { useQuery } from "@tanstack/react-query";
import { QUERY_KEYS } from "../constants/queryKeys";
import { User } from "../types/user";
import { StripeService } from "../services/stripe";

export const useCheckOnboarding = (): {
  isLoading: boolean;
  user: User | undefined;
} => {
  const { isSignedIn } = useUser();
  const navigate = useNavigate();
  const location = useLocation();

  const { data: subscriptionData } = useQuery({
    queryKey: [QUERY_KEYS.STRIPE.CHECK_SUBSCRIPTION],
    queryFn: StripeService.checkSubscription,
    enabled: !!isSignedIn,
  });

  const { data, isLoading } = useQuery({
    queryKey: [QUERY_KEYS.USER.ME],
    queryFn: UserService.getMe,
    enabled: !!isSignedIn,
  });

  if (!isSignedIn) {
    if (location.pathname !== "/") {
      navigate("/");
    }
    return { isLoading, user: data };
  }

  if (subscriptionData?.isActiveSubscription === false) {
    if (location.pathname !== "/subscription") {
      navigate("/subscription");
    }
    return { isLoading, user: data };
  }

  if (data?.onboardingCompleted !== true) {
    if (location.pathname !== "/onboarding") {
      navigate("/onboarding");
    }
    return { isLoading, user: data };
  }

  if (location.pathname !== "/dashboard") {
    navigate("/dashboard");
  }

  return { isLoading, user: data };
};
