import LoginForm from "./LoginForm";
import BlueGradient from "../../Images/Gradient2.svg";
import MenuBar from "../../Menu/MenuBar";
import { useCheckOnboarding } from "../../../hooks/useCheckOnboarding";

const signupFormStyle = {
  backgroundImage: `url(${BlueGradient})`,
  backgroundSize: "cover",
};

function Login() {
  const { isLoading } = useCheckOnboarding();

  if (isLoading) {
    return <div>Loading...</div>;
  }

  return (
    <div className="min-h-screen flex flex-col" style={signupFormStyle}>
      <div>
        <MenuBar />
      </div>

      <div className="flex-grow flex items-center justify-center py-8">
        <div className="max-w-[1440px] w-full mx-auto px-4">
          <div className="w-full flex flex-col lg:flex-row justify-center items-center">
            {/* Left Col */}
            <div className="w-full lg:w-1/2 flex flex-col justify-center items-center mb-8 lg:mb-0">
              {/* mobile view */}
              <div className="w-full lg:hidden flex flex-col justify-center items-center">
                <h1 className="leading-none text-center text-7xl text-brand-logo">
                  <span className="hidden leading-none text-center">
                    sloane<span className="font-Raleway -ml-2">.</span>
                  </span>
                </h1>
              </div>

              {/* desktop view */}
              <div className="w-1/2 hidden lg:block">
                <h1 className="leading-none text-left ml-2 text-brand-logo">
                  <span className="hidden lg:block leading-none text-left ml-2 text-9xl">
                    sloane<span className="font-Raleway -ml-2">.</span>
                  </span>
                </h1>
              </div>
            </div>

            {/* Right Col login*/}
            <div className="w-full lg:w-1/2 flex flex-col justify-center items-center">
              <div className=" flex justify-center">
                <LoginForm />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Login;
