/* eslint-disable react-hooks/exhaustive-deps */
import { useUser } from "@clerk/clerk-react";
import React, { useEffect, useRef, useState } from "react";
import { Socket, io } from "socket.io-client";
import "../../../App.css";
import { useChat } from "../../../context/ChatContext";
import Icon from "../../Icons/Icon";
import Markdown from "./Markdown";
import TextInput from "./TextInput";
import { useTheme } from "./ThemeContext";
import { useSearchParams } from "react-router-dom";
import { useAssistant } from "../../../context/assistantContext";
import SaveActionModal from "./SaveActionModal";
import { FaCopy, FaListAlt } from "react-icons/fa";
import { showSuccessToast } from "../../Toast/SuccessToast";

interface IMessage {
  _id: string;
  question: string;
  answer?: string;
  component?: React.ReactNode;
}

const ChatWindow: React.FC = () => {
  const {
    selectedChat,
    setSelectedChat,
    createChat,
    question,
    sendChat,
    selectChat,
    isMessageLoading,
    saveAction,
  } = useChat();
  const { prompts } = useAssistant();
  const { user } = useUser();
  const { theme } = useTheme();
  const [searchParams] = useSearchParams();
  const [response, setResponse] = useState<string>("");
  const [isUserScrolling, setIsUserScrolling] = useState<boolean>(false);
  const [showPrompts, setShowPrompts] = useState<boolean>(true);
  const hiddenTextAreaRef = useRef<HTMLTextAreaElement>(null);
  const socketRef = useRef<Socket | null>(null);
  const messagesEndRef = useRef<HTMLDivElement | null>(null);
  const chatContainerRef = useRef<HTMLDivElement | null>(null);
  const [waitingMessage, setWaitingMessage] = useState<string>("");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedColumn] = useState("idea");
  const [currentText, setCurrentText] = useState("");
  const [copied, setCopied] = useState(false);
  const [saved, setSaved] = useState(false);
  const hiddenMarkdownRef = useRef<HTMLDivElement>(null);
  const [currentCopyText, setCurrentCopyText] = useState("");

  const handleOpenAiResponse = (fullResponse: string) => {
    console.log("Streaming response:", fullResponse);
    const cleanedResponse = fullResponse
      .replace(/^```markdown\n/, "") // Remove opening markdown tag if at start
      .replace(/\n```$/, "") // Remove closing markdown tag if at end
      .trim();

    setSelectedChat((currentChat: any) => {
      if (!currentChat || currentChat._id !== selectedChat?._id)
        return currentChat;
      const newMessages = [...currentChat.messages];
      newMessages[newMessages.length - 1] = {
        _id: Date.now().toString(),
        question: question,
        answer: cleanedResponse || "",
      };
      return {
        ...currentChat,
        messages: newMessages,
      };
    });
  };

  const handleStreamEnd = (fullResponse: string) => {
    const cleanedResponse = fullResponse
      .replace(/^```markdown\n/, "") // Remove opening markdown tag if at start
      .replace(/\n```$/, "") // Remove closing markdown tag if at end
      .trim();

    setResponse(cleanedResponse);

    setSelectedChat((currentChat: any) => {
      if (!currentChat || currentChat._id !== selectedChat?._id)
        return currentChat;
      const newMessages = [...currentChat.messages];
      newMessages[newMessages.length - 1] = {
        _id: Date.now().toString(),
        question: question,
        answer: cleanedResponse,
      };
      return {
        ...currentChat,
        messages: newMessages,
      };
    });
  };

  useEffect(() => {
    if (selectedChat) {
      if (socketRef.current) {
        socketRef.current.disconnect();
        socketRef.current = null;
      }

      socketRef.current = io(process.env.REACT_APP_API_BASE_URL ?? "");
      socketRef.current.on("openai_response", handleOpenAiResponse);
      socketRef.current.on("stream_end", handleStreamEnd);
      socketRef.current.emit("joinRoom", selectedChat._id);

      return () => {
        if (socketRef.current) {
          socketRef.current.off("openai_response", handleOpenAiResponse);
          socketRef.current.off("stream_end", handleStreamEnd);
          socketRef.current.disconnect();
          socketRef.current = null;
        }
      };
    }
  }, [selectedChat, question]);

  useEffect(() => {
    const chatId = searchParams.get("chat");
    if (chatId) {
      selectChat(chatId);
    }
  }, [searchParams]);

  useEffect(() => {
    let timeoutId: NodeJS.Timeout;

    if (isMessageLoading) {
      setWaitingMessage("Let me work on that ...");

      timeoutId = setTimeout(() => {
        setWaitingMessage("Still thinking ...");
      }, 5000);
    } else {
      setWaitingMessage("");
    }

    return () => clearTimeout(timeoutId);
  }, [isMessageLoading]);

  const handlePromptClick = (prompt: string) => {
    sendChat(prompt, selectedChat?._id ?? "defaultRoom");
    setShowPrompts(false);
  };

  const handleCopyClick = (text: string) => {
    setCurrentCopyText(text);

    setTimeout(() => {
      if (hiddenMarkdownRef.current) {
        const selection = window.getSelection();
        const range = document.createRange();
        range.selectNodeContents(hiddenMarkdownRef.current);
        selection?.removeAllRanges();
        selection?.addRange(range);
        document.execCommand("copy");
        selection?.removeAllRanges();
        showSuccessToast("Text copied to clipboard!", true, "copy");
        setCopied(true);
        setTimeout(() => setCopied(false), 2000);
      }
    }, 0);
  };

  const handleActionClick = (text: string) => {
    setCurrentText(text);
    setIsModalOpen(true);
    setSaved(true);
    setTimeout(() => setSaved(false), 2000);
  };

  const handleSaveAction = async ({
    title,
    text,
    column,
    notes,
    colour,
    dueDate,
    tags,
    description,
    priority,
  }: {
    title: string;
    text: string;
    column: string;
    notes: string[];
    colour: string;
    dueDate: string;
    tags: string[];
    description: string;
    priority: string;
  }) => {
    try {
      if (!selectedChat || !currentText) return;

      await saveAction({
        title,
        text: currentText,
        column,
        notes,
        colour,
        dueDate,
        tags,
        description,
        priority,
      });

      showSuccessToast("Action saved successfully!", true, "action");
      setIsModalOpen(false);
    } catch (error) {
      console.error("Error saving action:", error);
      showSuccessToast("Failed to save action", true, "action");
    }
  };

  const handleUserScroll = () => {
    if (!chatContainerRef.current) return;
    const { scrollTop, scrollHeight, clientHeight } = chatContainerRef.current;
    setIsUserScrolling(scrollHeight - scrollTop > clientHeight + 50);
  };

  const handleKeyPress = (event: KeyboardEvent) => {
    if (event.key === "Enter") {
      messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
      setResponse("");
    }
  };

  const questionClass = `text-[16px] mb-6 ${
    theme === "dark" ? "text-brand-cream" : "text-brand-green"
  }`;

  useEffect(() => {
    window.addEventListener("keydown", handleKeyPress);
    return () => {
      window.removeEventListener("keydown", handleKeyPress);
    };
  }, []);

  useEffect(() => {
    if (!isUserScrolling && messagesEndRef.current) {
      const scrollOptions: ScrollIntoViewOptions = {
        behavior: isMessageLoading ? "auto" : "smooth",
        block: "end" as ScrollLogicalPosition,
      };
      messagesEndRef.current.scrollIntoView(scrollOptions);
    }
  }, [selectedChat?.messages, isUserScrolling, isMessageLoading]);

  useEffect(() => {
    if (selectedChat) {
      setShowPrompts(false);
    }
  }, [selectedChat]);

  // const processMarkdown = (text: string) => {
  //   return text;
  // };

  return (
    <div
      className="flex flex-col w-full max-w-full overflow-hidden items-center  pb-8 lg:pb-12 xl:pb-0"
      style={{
        height: "calc(100vh - 140px)",
        minHeight: "400px",
        position: "relative",
        paddingTop: "0px",
      }}
    >
      <div
        ref={hiddenMarkdownRef}
        style={{
          position: "absolute",
          left: "-9999px",
          top: 0,
          width: "100%",
          background: "#fff",
        }}
        className="[&_*]:!text-black [&_*]:!bg-white [&_table]:border-black [&_td]:border-black [&_th]:border-black [&_h1]:!text-black [&_h2]:!text-black [&_h3]:!text-black [&_p]:!text-black [&_li]:!text-black [&_ul]:!text-black [&_ol]:!text-black [&_strong]:!text-black [&_em]:!text-black"
      >
        <Markdown data={currentCopyText} />
      </div>
      <div
        className="flex-1 overflow-auto hide-scrollbar w-full"
        ref={chatContainerRef}
        onScroll={handleUserScroll}
      >
        {selectedChat?.messages && selectedChat.messages.length > 0 ? (
          selectedChat.messages.map((message: IMessage, index: number) => (
            <div
              className="w-full max-w-[800px] mx-auto px-4 my-8"
              key={message._id}
            >
              <div className="flex items-start">
                <img
                  src={user?.imageUrl}
                  alt="profile"
                  className="mr-2"
                  style={{ width: "24px", height: "24px", borderRadius: "50%" }}
                />
                <div className="flex flex-col mb-4">
                  <p
                    className={`${
                      theme === "dark" ? "text-brand-cream" : "text-brand-green"
                    } text-[16px] font-bold`}
                  >
                    You
                  </p>
                  <p className={questionClass}>
                    {message?.question?.split("\n")?.map((question, idx) => (
                      <span key={idx}>
                        {question}
                        <br />
                      </span>
                    ))}
                  </p>
                </div>
              </div>
              {message.component ? (
                <Markdown data={response} />
              ) : (
                <div className="flex items-start">
                  <Icon name="ai" className="mr-2 -mt-4" />
                  <div className="flex flex-col">
                    <p
                      className={`${
                        theme === "dark"
                          ? "text-brand-logo"
                          : "text-brand-green"
                      } text-[16px] font-bold mb-2`}
                    >
                      sloane
                    </p>
                    {isMessageLoading &&
                    index === selectedChat.messages.length - 1 &&
                    !message.answer ? (
                      <div
                        className={`${
                          theme === "dark"
                            ? "text-brand-cream"
                            : "text-brand-green"
                        }`}
                      >
                        {waitingMessage}
                        <span className="animate-ellipsis">...</span>
                      </div>
                    ) : (
                      <>
                        <Markdown data={message.answer ?? ""} />
                        <div className="w-full flex justify-center gap-3 items-center relative -ml-6 mb-4">
                          <button
                            className={`flex items-center gap-1.5 text-sm px-3 py-1 rounded-lg 
                              ${
                                theme === "dark"
                                  ? "text-brand-cream/80 bg-brand-cream/10 hover:bg-brand-cream/20"
                                  : "text-brand-green/80 bg-brand-green/10 hover:bg-brand-green/20"
                              } transition-all duration-300 ease-in-out group overflow-hidden`}
                            onClick={() =>
                              handleCopyClick(message.answer ?? "")
                            }
                          >
                            <span className="flex items-center gap-1.5">
                              <FaCopy className="text-xs opacity-70 min-w-[14px]" />
                              <span className="min-w-[30px]">Copy</span>
                              <span className="group-hover:max-w-[150px] max-w-0 overflow-hidden whitespace-nowrap transition-all duration-300 ease-in-out">
                                {copied ? " (Copied!)" : " Response"}
                              </span>
                            </span>
                          </button>

                          <button
                            className={`flex items-center gap-1.5 text-sm px-3 py-1 rounded-lg 
                              ${
                                theme === "dark"
                                  ? "text-brand-cream/80 bg-brand-cream/10 hover:bg-brand-cream/20"
                                  : "text-brand-green/80 bg-brand-green/10 hover:bg-brand-green/20"
                              } transition-all duration-300 ease-in-out group overflow-hidden`}
                            onClick={() =>
                              handleActionClick(message.answer || "")
                            }
                          >
                            <span className="flex items-center gap-1.5">
                              <FaListAlt className="text-xs opacity-70 min-w-[14px]" />
                              <span className="min-w-[30px]">Save</span>
                              <span className="group-hover:max-w-[150px] max-w-0 overflow-hidden whitespace-nowrap transition-all duration-300 ease-in-out">
                                {saved ? " (Added!)" : " as Action"}
                              </span>
                            </span>
                          </button>
                        </div>
                      </>
                    )}
                  </div>
                </div>
              )}
              {index === selectedChat?.messages.length - 1 && (
                <div ref={messagesEndRef} />
              )}
            </div>
          ))
        ) : (
          <div className="w-full max-w-[800px] mx-auto px-4 my-8 text-center">
            <p
              className={`${
                theme === "dark" ? "text-brand-cream" : "text-brand-green"
              }`}
            >
              This chat has no messages yet. Start a conversation by typing a
              message below.
            </p>
          </div>
        )}
      </div>

      {/* Prompts */}
      {showPrompts && (
        <div className="flex flex-wrap justify-center py-6 w-full max-w-[800px] mx-auto step-2 mt-4">
          {prompts.map((prompt) => (
            <button
              key={Math.random()}
              className="mx-2 p-2 mb-2 bg-brand-cream text-brand-green text-[12px] rounded-[100px] hover:text-brand-green-dark shadow-sm transform transition-transform duration-300 ease-in-out hover:scale-110 hover:cursor-pointer border-[1px] border-brand-green hover:bg-brand-cream/90"
              onClick={() => handlePromptClick(prompt.prompt)}
            >
              {prompt.display}
            </button>
          ))}
        </div>
      )}

      <button
        className={`mb-6 lg:mb-4 ${
          theme === "dark"
            ? "text-brand-cream border border-brand-cream/70 px-4"
            : "text-brand-green border border-brand-green/70 px-4"
        } text-[12px] rounded-[100px] transform transition-transform duration-300 ease-in-out hover:scale-110 hover:cursor-pointer step-3`}
        onClick={() => setShowPrompts(!showPrompts)}
      >
        {showPrompts ? "Hide Prompts" : "Show Prompts"}
      </button>

      {selectedChat ? (
        <div className="w-full max-w-[900px] mx-auto px-4  relative mb-6 lg:mb-0">
          <TextInput room={selectedChat._id ?? "defaultRoom"} />
          <p
            className={`text-xs ${
              theme === "dark" ? "text-brand-cream/70" : "text-brand-green"
            } text-center`}
          >
            Sloane can make mistakes, please check important information
          </p>
        </div>
      ) : (
        <div
          onClick={() => {
            createChat();
            setShowPrompts(true);
          }}
          className="absolute top-[40%] border-brand-cream hidden md:block px-4 py-2 rounded-[50px] border-[1px] cursor-pointer"
        >
          <p
            className={`text-2xl font-thin flex justify-center items-center mb-0 step-1 ${
              theme === "dark" ? "text-brand-cream" : "text-brand-green-dark"
            }`}
          >
            Click To Begin New Chat
          </p>
        </div>
      )}
      <textarea
        ref={hiddenTextAreaRef}
        style={{ position: "absolute", left: "-9999px", top: "0" }}
      />

      <SaveActionModal
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        onSave={handleSaveAction}
        selectedColumn={selectedColumn}
        text={currentText}
        hideSloaneChatSection={false}
      />
    </div>
  );
};

export default ChatWindow;
