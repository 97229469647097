import { useSubscriptionStatus } from "../../../hooks/useSubscriptionStatus";
import DashboardContent from "./DashboardContent";
import { useChat } from "../../../context/ChatContext";
import LoadingSpinner from "./LoadingSpinner";
import { useCheckOnboarding } from "../../../hooks/useCheckOnboarding";

const Dashboard: React.FC = () => {
  const { isLoading } = useSubscriptionStatus();
  const { setSelectedChat } = useChat();
  useCheckOnboarding();

  if (isLoading) {
    return (
      <div className="w-screen h-screen bg-brand-green flex justify-center items-center text-brand-orange">
        <LoadingSpinner />
      </div>
    );
  }

  setSelectedChat(undefined);
  // Add the PleaseSubscribe component and the DashboardContent component here.

  return (
    <div className="w-full min-h-screen bg-brand-green overflow-x-hidden">
      <div className="w-full flex flex-col justify-between h-full">
        {/* {!isSubscribed ? (
					<PleaseSubscribe onClickSubscribe={handleClickSubscribe} />
				) : (
					<DashboardContent onOpenPomodoro={onOpenPomodoro} />
				)} */}
        <DashboardContent />
      </div>
    </div>
  );
};

export default Dashboard;
