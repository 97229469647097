/******************************************************************************************
                                    IMPORTS
******************************************************************************************/
import { useState, useEffect, useCallback } from "react";
import { useAssistants } from "../../../hooks/useAssistants";
// import MaintenanceAnnouncement from "../../Announcement/MaintenanceAnnouncement";
import MindfulnessModal from "../../Mindfulness/MindfulnessModal";
import { useTimer } from "../../../context/TimerContext";

/******************************************************************************************
                                    COMPONENTS
******************************************************************************************/
import Square from "./Square";
import Square2 from "./Square2"; // Import Square2 for custom hubs
// import { tourSteps } from "../../../config/tourSteps";
import PrimaryModal from "./PrimaryModal";
import CreateAssistantModal from "./CreateAssistantModal";
import Sidebar from "../../Sidebar/Sidebar";
import GoalsSection from "./GoalsSection";

/******************************************************************************************
                                    TYPES
******************************************************************************************/
import { IAssistant } from "../../../types/interfaces";

/******************************************************************************************
                                    HOOKS
******************************************************************************************/
import { useAuth, useUser } from "@clerk/clerk-react";
// import Joyride, { CallBackProps, STATUS } from "react-joyride";

interface Goal {
  title: string;
  isCompleted: boolean;
  _id: string;
}

const DashboardContent = () => {
  const { assistants } = useAssistants();
  const { user } = useUser();
  const { getToken } = useAuth();
  // const [run, setRun] = useState(false);
  const [isPrimaryModalOpen, setIsPrimaryModalOpen] = useState(false); // State for PrimaryModal
  const [isCreateModalOpen, setIsCreateModalOpen] = useState(false);
  // const [showAnnouncement, setShowAnnouncement] = useState(false); // State for showing announcement
  const [showMindfulness, setShowMindfulness] = useState(false);
  // const [showPomodoro, setShowPomodoro] = useState(false);
  // const [isTimerActive, setIsTimerActive] = useState(false);
  // const [timerConfig, setTimerConfig] = useState({ work: 25, break: 5 });
  const { openPomodoroModal } = useTimer();

  const [weeklyGoals, setWeeklyGoals] = useState<Goal[]>([]);
  const [monthlyGoals, setMonthlyGoals] = useState<Goal[]>([]);
  const [isGoalsEnabled, setIsGoalsEnabled] = useState(true);

  // Filter assistants into standard and custom categories
  const standardAssistants = assistants.filter((assistant) => !assistant.user);

  const customAssistants = assistants.filter(
    (assistant) => assistant.user === user?.id
  );

  // const handleJoyrideCallback = (data: CallBackProps) => {
  // 	const { status, action, type } = data;
  // 	if (
  // 		status === STATUS.FINISHED ||
  // 		status === STATUS.SKIPPED ||
  // 		(action === "close" && type === "step:after")
  // 	) {
  // 		localStorage.setItem(storageKey, "true");
  // 		setRun(false);
  // 	}
  // };

  /******************************************************************************************
                                    TOUR HANDLING
******************************************************************************************/
  // useEffect(() => {
  // 	const tourShown = localStorage.getItem(storageKey);
  // 	if (tourShown !== "true") {
  // 		const timer = setTimeout(() => {
  // 			setRun(true);
  // 		}, 2000);
  // 		return () => {
  // 			clearTimeout(timer);
  // 		};
  // 	} else {
  // 		setRun(false);
  // 	}
  // }, [storageKey]);

  /******************************************************************************************
                                    PRIMARY MODAL HANDLING
******************************************************************************************/
  const handleOpenPrimaryModal = () => setIsPrimaryModalOpen(true);
  const handleClosePrimaryModal = () => setIsPrimaryModalOpen(false);

  const handleNewAssistantModal = () => {
    setIsCreateModalOpen(true);
  };

  useEffect(() => {
    const checkMindfulnessPrompt = () => {
      const now = new Date();
      const lastSession = localStorage.getItem("lastMindfulnessSession");
      const nextShowStr = localStorage.getItem("mindfulnessNextShow");
      const autoShowEnabled =
        localStorage.getItem("mindfulnessAutoShow") !== "false";

      // If autoShow is disabled, don't show the modal
      if (!autoShowEnabled) return;

      // If there's a next show time set (from skip), check if we should show
      if (nextShowStr) {
        const nextShow = new Date(nextShowStr);
        if (now < nextShow) return;
      }

      // Check if we've already shown today
      if (lastSession) {
        const lastSessionDate = new Date(JSON.parse(lastSession).date);
        const startOfDay = new Date(now);
        startOfDay.setHours(3, 0, 0, 0); // Reset to 3 AM

        // If we've already had a session today, don't show
        if (lastSessionDate >= startOfDay) return;
      }

      // If we get here, we should show the modal after delay
      setTimeout(() => {
        setShowMindfulness(true);
      }, 2000);
    };

    checkMindfulnessPrompt();
  }, []); // Only run on mount

  const handleOpenMindfulness = () => {
    setShowMindfulness(true);
  };

  const handleOpenPomodoro = () => {
    setShowMindfulness(false);
    openPomodoroModal();
  };

  const fetchGoals = useCallback(async () => {
    try {
      const token = await getToken();
      if (!token) return;
      const response = await fetch(
        `${process.env.REACT_APP_API_BASE_URL}/api/goals`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      const data = await response.json();
      const { weeklyGoals, monthlyGoals, isEnabled } = data;
      setWeeklyGoals(weeklyGoals);
      setMonthlyGoals(monthlyGoals);
      setIsGoalsEnabled(isEnabled);
    } catch (error) {
      console.error("Error fetching goals:", error);
    }
  }, [getToken, setWeeklyGoals, setMonthlyGoals, setIsGoalsEnabled]);

  useEffect(() => {
    fetchGoals();
  }, [user, fetchGoals]);

  const handleSaveGoals = async (
    newWeeklyGoals: string[],
    newMonthlyGoals: string[]
  ) => {
    try {
      const token = await getToken();
      if (!token) return;

      const response = await fetch(
        `${process.env.REACT_APP_API_BASE_URL}/api/goals`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify({
            weeklyGoals: newWeeklyGoals,
            monthlyGoals: newMonthlyGoals,
          }),
        }
      );

      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      const data = await response.json();
      console.log("Save goals response:", data);
      fetchGoals();
    } catch (error) {
      console.error("Error saving goals:", error);
    }
  };

  const handleToggleGoalComplete = async (
    goalId: string,
    type: "weekly" | "monthly"
  ) => {
    try {
      const token = await getToken();
      if (!token) return;
      const response = await fetch(
        `${process.env.REACT_APP_API_BASE_URL}/api/goals/${goalId}/toggle`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify({ type }),
        }
      );

      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      fetchGoals();
    } catch (error) {
      console.error("Error toggling goal:", error);
    }
  };

  const handleToggleGoals = async () => {
    try {
      const token = await getToken();
      if (!token) return;
      const response = await fetch(
        `${process.env.REACT_APP_API_BASE_URL}/api/goals/toggle`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      setIsGoalsEnabled(!isGoalsEnabled);
    } catch (error) {
      console.error("Error toggling goals feature:", error);
    }
  };

  // Add scrollbar styles at the top of the component
  const scrollbarStyles = `
    .dashboard-scroll::-webkit-scrollbar {
      width: 5px;
    }
    .dashboard-scroll::-webkit-scrollbar-track {
      background: transparent;
    }
    .dashboard-scroll::-webkit-scrollbar-thumb {
      background: #FDF3E3;
      opacity: 0.3;
    }
    .dashboard-scroll::-webkit-scrollbar-thumb:hover {
      background: #FDF3E3;
      opacity: 0.5;
    }
  `;

  return (
    <div className="w-full min-h-screen bg-brand-green">
      <style>{scrollbarStyles}</style>
      <Sidebar
        onNewAssistant={handleNewAssistantModal}
        onUpdateModel={handleOpenPrimaryModal}
        onOpenMindfulness={handleOpenMindfulness}
        onOpenPomodoro={openPomodoroModal}
      />

      <div className="lg:pl-[88px] xl:pl-[248px] transition-all duration-300">
        <div className="h-screen overflow-y-auto dashboard-scroll">
          <div className="p-6 pt-0">
            <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-3 sm:gap-5 md:gap-6 lg:gap-8">
              <div className="col-span-2 md:col-span-3 lg:col-span-4 pt-20 md:pt-14 xl:pl-8">
                <h5 className="font-Black text-brand-orange-cream text-brand-cream text-[26px] md:text-5xl leading-none">
                  Hi {user?.firstName},
                </h5>
                <h5 className="font-Black text-brand-cream text-[26px] md:text-5xl">
                  How can we help you today?
                </h5>
              </div>

              {/* Goals Section */}
              <div className="col-span-2 md:col-span-3 lg:col-span-4 xl:pl-8">
                <GoalsSection
                  weeklyGoals={weeklyGoals}
                  monthlyGoals={monthlyGoals}
                  isEnabled={isGoalsEnabled}
                  onToggleGoals={handleToggleGoals}
                  onToggleGoalComplete={handleToggleGoalComplete}
                  onSave={handleSaveGoals}
                />
              </div>

              {/* Standard Assistants */}
              {standardAssistants &&
                standardAssistants.map((assistant: IAssistant, index: number) => (
                  <div
                    key={index}
                    className="w-[160px] sm:w-[200px] md:w-[220px] lg:w-[210px] h-[100px] sm:h-[110px] md:h-[120px] relative mx-auto"
                  >
                    <Square
                      title={assistant.jobTitle}
                      description={assistant.description}
                      _id={assistant._id}
                    />
                  </div>
                ))}

              {/* Custom Assistants Section - Only show if user has custom assistants */}
              {customAssistants.length > 0 && (
                <>
                  {customAssistants.map(
                    (assistant: IAssistant, index: number) => (
                      <div
                        key={`custom-${index}`}
                        className="w-[160px] sm:w-[200px] md:w-[220px] lg:w-[210px] h-[100px] sm:h-[110px] md:h-[120px] relative mx-auto"
                      >
                        <Square2
                          title={assistant.jobTitle}
                          description={assistant.description}
                          _id={assistant._id}
                        />
                      </div>
                    )
                  )}
                </>
              )}
            </div>
          </div>
        </div>
      </div>

      {/* PrimaryModal component handling */}
      {isPrimaryModalOpen && <PrimaryModal onClose={handleClosePrimaryModal} />}

      <CreateAssistantModal
        isOpen={isCreateModalOpen}
        closeModal={() => setIsCreateModalOpen(false)}
      />

      <MindfulnessModal
        isOpen={showMindfulness}
        onClose={() => setShowMindfulness(false)}
        onStartTimer={handleOpenPomodoro}
      />
    </div>
  );
};

export default DashboardContent;
