import React, { useEffect, useState, useCallback } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { useTheme } from "./ThemeContext";
import ChatWindow from "./ChatWindow";
import Sidebar from "./Sidebar";
import ThemeSwitch from "./ThemeSwitch";
import { service } from "../../../services";
import { useAuth } from "@clerk/clerk-react";
import RelatedAssistantsPopup from "./RelatedAssistantsPopup";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { useAssistant } from "../../../context/assistantContext";
import { useChat } from "../../../context/ChatContext";
// import { useFolder } from "../../../context/FolderContext";
import HomeIcon from "@mui/icons-material/Home";
import AddCommentIcon from "@mui/icons-material/AddComment";
import CloseIcon from "@mui/icons-material/Close";
import MenuIcon from "@mui/icons-material/Menu";
import FullPageMenu from "./FullPageMenu";
import { FaUsers } from "react-icons/fa";

const Chat = () => {
  const { assistantId } = useParams();
  const navigate = useNavigate();
  const { theme } = useTheme();
  const { getToken } = useAuth();
  const { setSelectedAssistant } = useAssistant();
  const { createChat, setSelectedChat } = useChat();
  // const { createFolder } = useFolder();

  const [assistantData, setAssistantData] = useState<any>(null);
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [isFullPageMenuOpen, setIsFullPageMenuOpen] = useState(false);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  // Track window resize
  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
      // Show sidebar by default on large screens, hide on medium and small screens
      if (window.innerWidth >= 1280) {
        setIsSidebarOpen(true);
      } else {
        setIsSidebarOpen(false);
      }
    };

    window.addEventListener("resize", handleResize);
    // Initial check
    handleResize();

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  // Apply theme based on screen size
  useEffect(() => {
    // We'll keep the theme consistent across all screen sizes
    // No longer forcing light theme on mobile
  }, [windowWidth]);

  const toggleSidebar = useCallback(() => {
    setIsSidebarOpen((prev) => !prev);
  }, []);

  const togglePopup = useCallback(() => {
    setIsPopupOpen((prev) => !prev);
  }, []);

  // Listen for related assistants toggle event
  useEffect(() => {
    const handleToggleRelatedAssistants = () => {
      togglePopup();
    };
    window.addEventListener(
      "toggleRelatedAssistants",
      handleToggleRelatedAssistants
    );

    return () => {
      window.removeEventListener(
        "toggleRelatedAssistants",
        handleToggleRelatedAssistants
      );
    };
  }, [togglePopup]);

  const handleNewChat = () => {
    if (assistantId) {
      setSelectedChat(undefined);
      createChat();
      navigate(`/assistant/${assistantId}`);
    }
  };

  const handleNavigateToDashboard = () => {
    navigate("/dashboard");
  };

  useEffect(() => {
    const getData = async () => {
      if (!assistantId) return;

      try {
        setSelectedAssistant(assistantId);
        const token = await getToken();
        const assistant = await service.assistantService.getAssistant(
          token,
          assistantId
        );

        if (assistant) {
          setAssistantData(assistant);
        }
      } catch (error) {
        console.error("Error fetching assistant:", error);
      }
    };

    getData();
  }, [assistantId, getToken, setSelectedAssistant]);

  // Determine the title to display
  const hubTitle = assistantId && assistantData ? assistantData.jobTitle : "";

  return (
    <DndProvider backend={HTML5Backend}>
      <div
        className={`${
          theme === "dark" ? "bg-brand-green-dark" : "bg-brand-cream"
        } w-full h-screen flex flex-col overflow-hidden`}
      >
        {/* Mobile Header */}
        {windowWidth < 768 && (
          <div className="w-full flex items-center justify-center border-b">
            <h2
              className={`text-center text-xl font-bold mt-14 ${
                theme === "dark" ? "text-brand-cream" : "text-brand-green"
              }`}
            >
              {hubTitle}
            </h2>
          </div>
        )}

        <div className="flex flex-1 overflow-hidden">
          {/* Sidebar */}
          <div
            className={`${
              windowWidth < 1280
                ? "fixed inset-y-0 left-0 z-40 w-64 transform transition-transform duration-300 ease-in-out bg-white"
                : "relative w-1/5 bg-brand-green"
            } ${
              isSidebarOpen
                ? "translate-x-0"
                : windowWidth < 1280
                ? "-translate-x-full"
                : ""
            }`}
          >
            {windowWidth < 1280 && (
              <div className="flex justify-center p-2 border-b">
                <button
                  onClick={toggleSidebar}
                  className="p-2 rounded-full hover:bg-gray-100"
                >
                  <CloseIcon className="text-brand-green-dark" />
                </button>
              </div>
            )}
            <Sidebar setIsSidebarOpen={setIsSidebarOpen} />
          </div>

          {/* Overlay for mobile sidebar */}
          {windowWidth < 768 && isSidebarOpen && (
            <div
              className="fixed inset-0 bg-black bg-opacity-50 z-30"
              onClick={toggleSidebar}
            ></div>
          )}

          {/* Main Content */}
          <div
            className={`flex-1 flex flex-col overflow-hidden ${
              windowWidth >= 1280 ? "" : ""
            }`}
          >
            <div
              className={`w-full max-w-4xl mx-auto px-4 md:px-6 lg:px-8 flex flex-col flex-1 overflow-hidden ${
                windowWidth < 1280 ? "pb-16" : ""
              }`}
            >
              {/* Desktop Header with Theme Switch and Related Hubs */}
              {/* have heading green on light mode */}

              {windowWidth >= 768 && (
                <div className="flex items-center justify-center py-6 mb-4 w-full">
                  <h1
                    className={`text-2xl md:text-3xl lg:text-4xl font-bold ${
                      theme === "dark" ? "text-brand-cream" : "text-brand-green"
                    } text-center`}
                  >
                    {hubTitle}
                  </h1>
                </div>
              )}

              {/* Chat Window */}
              <div className="flex-1 overflow-hidden h-full">
                <ChatWindow />
              </div>
            </div>

            {/* Theme Switch - Bottom Right of Screen */}
            {windowWidth >= 768 && (
              <div className="fixed bottom-6 right-6 z-50">
                <ThemeSwitch />
              </div>
            )}

            {/* Related Assistants Button - Right of Sidebar */}
            {windowWidth >= 1280 && (
              <div className="fixed left-[calc(20%+12px)] bottom-6 z-50">
                <button
                  onClick={togglePopup}
                  className={`flex items-center gap-2 text-sm px-3 py-2 rounded-lg 
                  ${
                    theme === "dark"
                      ? "text-brand-cream/80 bg-brand-cream/10 hover:bg-brand-cream/20"
                      : "text-brand-green/80 bg-brand-green/10 hover:bg-brand-green/20"
                  } transition-all duration-300 ease-in-out shadow-sm`}
                  aria-label="Show related assistants"
                >
                  <FaUsers
                    className={`${
                      theme === "dark"
                        ? "text-brand-cream/80"
                        : "text-brand-green/80"
                    } text-2xl`}
                  />
                </button>
              </div>
            )}
          </div>
        </div>

        {/* Related Assistants Popup */}
        {assistantId && assistantData && (
          <RelatedAssistantsPopup
            relatedAssistants={assistantData.relatedAssistants}
            onClose={togglePopup}
            isOpen={isPopupOpen}
          />
        )}

        {/* Full Page Menu */}
        <FullPageMenu
          isOpen={isFullPageMenuOpen}
          onClose={() => setIsFullPageMenuOpen(false)}
          hubTitle={hubTitle}
        />

        {/* Bottom Navigation Bar - Mobile Only */}
        {windowWidth < 1280 && (
          <div className="fixed bottom-0 left-0 right-0 bg-brand-green border-t border-gray-200 flex justify-around items-center h-20 z-50 px-6 pb-6 pt-2">
            <button
              onClick={handleNavigateToDashboard}
              className="flex flex-col items-center justify-center p-2"
            >
              <HomeIcon
                className={`h-6 w-6 ${
                  theme === "dark" ? "text-brand-cream" : "text-brand-green"
                }`}
              />
              <span
                className={`text-xs mt-1 ${
                  theme === "dark" ? "text-brand-cream" : "text-brand-green"
                }`}
              >
                Dashboard
              </span>
            </button>
            <button
              onClick={handleNewChat}
              className="flex flex-col items-center justify-center p-2"
            >
              <AddCommentIcon
                className={`h-6 w-6 ${
                  theme === "dark" ? "text-brand-cream" : "text-brand-green"
                }`}
              />
              <span
                className={`text-xs mt-1 ${
                  theme === "dark" ? "text-brand-cream" : "text-brand-green"
                }`}
              >
                New Chat
              </span>
            </button>
            <button
              onClick={togglePopup}
              className="flex flex-col items-center justify-center p-2"
            >
              <FaUsers
                className={`h-6 w-6 ${
                  theme === "dark" ? "text-brand-cream" : "text-brand-green"
                }`}
              />
              <span
                className={`text-xs mt-1 ${
                  theme === "dark" ? "text-brand-cream" : "text-brand-green"
                }`}
              >
                Quick Links
              </span>
            </button>
            <button
              onClick={() => setIsFullPageMenuOpen(true)}
              className="flex flex-col items-center justify-center p-2"
            >
              <MenuIcon
                className={`h-6 w-6 ${
                  theme === "dark" ? "text-brand-cream" : "text-brand-green"
                }`}
              />
              <span
                className={`text-xs mt-1 ${
                  theme === "dark" ? "text-brand-cream" : "text-brand-green"
                }`}
              >
                All Chats
              </span>
            </button>
          </div>
        )}
      </div>
    </DndProvider>
  );
};

export default Chat;
