import fetcher from "../utils/fetcher";

export namespace StripeService {
  export const createCheckout = async () => {
    const response = await fetcher("/api/stripe/create-checkout", {
      method: "POST",
    });
    console.log(response.data);
    return response.data;
  };

  export const checkSubscription = async () => {
    const response = await fetcher("/api/stripe/check-subscription", {
      method: "GET",
    });
    return response.data;
  };
}
