import React from "react";
import { motion } from "framer-motion";

interface NewsReelProps {
  phrases: string[];
}

const NewsReel: React.FC<NewsReelProps> = ({ phrases }) => {
  return (
    <div className="w-screen bg-brand-cream overflow-hidden py-2 relative left-[50%] right-[50%] ml-[-50vw] mr-[-50vw]">
      <motion.div
        className="flex whitespace-nowrap"
        animate={{
          x: [0, -1000],
        }}
        transition={{
          x: {
            repeat: Infinity,
            repeatType: "loop",
            duration: 20,
            ease: "linear",
          },
        }}
      >
        {/* Triple the phrases for smoother loop */}
        {[...phrases, ...phrases, ...phrases].map((phrase, index) => (
          <span
            key={index}
            className="text-brand-green text-lg font-Black mx-8 inline-block"
          >
            {phrase}
          </span>
        ))}
      </motion.div>
    </div>
  );
};

export default NewsReel;
