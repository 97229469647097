import React from "react";
import { useNavigate } from "react-router-dom";
import { motion, AnimatePresence } from "framer-motion";
import Icon from "../../Icons/Icon";
import { useChat } from "../../../context/ChatContext";
import { FaTasks } from "react-icons/fa";

interface RelatedAssistantsPopupProps {
  relatedAssistants: { _id: string; name: string }[];
  onClose: () => void;
  isOpen: boolean;
}

const buttonVariants = {
  hidden: { opacity: 0, y: 20 },
  visible: { opacity: 1, y: 0 },
};

const containerVariants = {
  hidden: { opacity: 0 },
  visible: {
    opacity: 1,
    transition: {
      staggerChildren: 0.1,
    },
  },
};

const RelatedAssistantsPopup: React.FC<RelatedAssistantsPopupProps> = ({
  relatedAssistants = [],
  onClose,
  isOpen,
}) => {
  const navigate = useNavigate();
  const { setSelectedChat } = useChat();
  const [windowWidth, setWindowWidth] = React.useState(window.innerWidth);

  React.useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const handleNavigate = (assistantId: string) => {
    setSelectedChat(undefined);
    navigate(`/assistant/${assistantId}`);
    onClose();
  };

  // Determine position based on screen size
  const getPopupPosition = () => {
    if (windowWidth < 768) {
      return "fixed left-1/2 -translate-x-1/2 bottom-20 z-50"; // Center above bottom nav
    } else if (windowWidth < 1280) {
      return "fixed right-4 bottom-24 z-50"; // For medium screens
    } else {
      return "fixed left-[calc(20%+12px)] bottom-20 z-50"; // Directly above the button on large screens
    }
  };

  return (
    <AnimatePresence>
      {isOpen && (
        <motion.div
          className={`${getPopupPosition()} flex items-end justify-start`}
          initial="hidden"
          animate="visible"
          exit="hidden"
          variants={containerVariants}
        >
          <div className="bg-new-green p-3 rounded-lg shadow-lg border border-brand-green-dark/20 max-w-[90vw] md:max-w-xs">
            <ul className="space-y-2">
              {relatedAssistants.map((assistant) => (
                <motion.li key={assistant._id} variants={buttonVariants}>
                  <button
                    className="bg-brand-green/50 text-brand-cream/80 text-md shadow-lg hover:text-brand-green-dark hover:bg-brand-logo px-4 py-2 rounded-lg font-bold w-full text-left"
                    onClick={() => handleNavigate(assistant._id)}
                  >
                    {assistant.name}
                  </button>
                </motion.li>
              ))}
              <motion.li variants={buttonVariants}>
                <button className="w-full">
                  <div className="bg-brand-green/50 text-brand-cream/80 text-md shadow-lg hover:text-brand-green-dark hover:bg-brand-logo px-4 py-2 rounded-lg font-bold w-full text-left flex">
                    <div className="w-6 h-6 flex justify-start items-center mr-2">
                      <Icon name="canva" className="w-1/2 h-1/2 " />
                    </div>
                    <a
                      href="https://www.canva.com/"
                      target="_blank"
                      rel="noreferrer"
                      className="flex-1"
                    >
                      <p className="text-sm ml-2 font-bold">Canva</p>
                    </a>
                  </div>
                </button>
              </motion.li>
              <motion.li variants={buttonVariants}>
                <button className="w-full -mt-3">
                  <div className="bg-brand-green/50 text-brand-cream/80 text-md shadow-lg hover:text-brand-green-dark hover:bg-brand-logo px-4 py-2 rounded-lg font-bold w-full text-left flex">
                    <div className="w-6 h-6 flex justify-start items-center mr-2">
                      <FaTasks />
                    </div>
                    <a
                      href="/actions"
                      onClick={(e) => {
                        e.preventDefault();
                        navigate("/actions");
                        onClose();
                      }}
                      className="flex-1"
                    >
                      <p className="text-sm ml-2 font-bold">Action Plan</p>
                    </a>
                  </div>
                </button>
              </motion.li>
            </ul>
          </div>
        </motion.div>
      )}
    </AnimatePresence>
  );
};

export default RelatedAssistantsPopup;
