import React, { useState } from "react";
import {
  FaChevronDown,
  FaChevronUp,
  FaPencilAlt,
  FaCheck,
  FaTimes,
  FaPlus,
  FaStar,
  FaEdit,
  FaTrash,
  FaEnvelope,
} from "react-icons/fa";
import { Switch } from "@headlessui/react";
import { toast } from "react-hot-toast";
import { useAuth, useUser } from "@clerk/clerk-react";

interface Goal {
  title: string;
  isCompleted: boolean;
  _id: string;
}

interface GoalsSectionProps {
  weeklyGoals: Goal[];
  monthlyGoals: Goal[];
  isEnabled: boolean;
  onToggleGoals: () => void;
  onToggleGoalComplete: (goalId: string, type: "weekly" | "monthly") => void;
  onSave: (weeklyGoals: string[], monthlyGoals: string[]) => void;
}

const GoalsSection: React.FC<GoalsSectionProps> = ({
  weeklyGoals,
  monthlyGoals,
  isEnabled,
  onToggleGoals,
  onToggleGoalComplete,
  onSave,
}) => {
  const { getToken } = useAuth();
  const [isExpanded, setIsExpanded] = useState(false);
  const [editingGoal, setEditingGoal] = useState<{
    id: string;
    type: "weekly" | "monthly";
  } | null>(null);
  const [editValue, setEditValue] = useState("");
  const [newGoalType, setNewGoalType] = useState<"weekly" | "monthly" | null>(
    null
  );
  const [newGoalValue, setNewGoalValue] = useState("");
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { user } = useUser();

  const renderGoalIcons = (goals: Goal[]) => {
    return goals.map((goal, index) => (
      <div
        key={index}
        className={`w-6 h-6 rounded-full border ${
          goal.isCompleted
            ? "bg-brand-logo border-brand-logo"
            : "border-brand-cream/50"
        }`}
      />
    ));
  };

  const handleStartEdit = (goal: Goal, type: "weekly" | "monthly") => {
    setEditingGoal({ id: goal._id, type });
    setEditValue(goal.title);
  };

  const handleSaveEdit = () => {
    if (!editingGoal || !editValue.trim()) return;

    const updatedWeeklyGoals = weeklyGoals.map((g) => g.title);
    const updatedMonthlyGoals = monthlyGoals.map((g) => g.title);

    if (editingGoal.type === "weekly") {
      const index = weeklyGoals.findIndex((g) => g._id === editingGoal.id);
      if (index !== -1) {
        updatedWeeklyGoals[index] = editValue.trim();
      }
    } else {
      const index = monthlyGoals.findIndex((g) => g._id === editingGoal.id);
      if (index !== -1) {
        updatedMonthlyGoals[index] = editValue.trim();
      }
    }

    onSave(updatedWeeklyGoals, updatedMonthlyGoals);
    toast(
      (t) => (
        <div className="flex flex-col sm:flex-row items-center justify-between w-full gap-3 sm:gap-4">
          <div className="flex items-center gap-3">
            <div className="bg-[#4b8052]/10 p-2 rounded-full">
              <FaEdit className="text-[#4b8052] text-lg" />
            </div>
            <span className="text-[#4b8052] font-medium">
              GOAL UPDATED SUCCESSFULLY
            </span>
          </div>
          <button
            onClick={() => toast.dismiss(t.id)}
            className="px-3 py-1 bg-[#4b8052] text-white rounded-lg hover:bg-[#4b8052]/90 transition-colors mt-2 sm:mt-0 w-full sm:w-auto"
          >
            Okay
          </button>
        </div>
      ),
      {
        position: "top-center",
        duration: 3000,
        style: {
          background: "var(--brand-cream, #Fdf3e3)",
          padding: "16px",
          maxWidth: "95%",
          width: "auto",
          boxShadow: "0 4px 12px rgba(0, 0, 0, 0.1)",
          border: "1px solid rgba(0, 0, 0, 0.05)",
        },
      }
    );
    setEditingGoal(null);
    setEditValue("");
  };

  const handleDeleteGoal = (goalId: string, type: "weekly" | "monthly") => {
    const updatedWeeklyGoals =
      type === "weekly"
        ? weeklyGoals.filter((g) => g._id !== goalId).map((g) => g.title)
        : weeklyGoals.map((g) => g.title);

    const updatedMonthlyGoals =
      type === "monthly"
        ? monthlyGoals.filter((g) => g._id !== goalId).map((g) => g.title)
        : monthlyGoals.map((g) => g.title);

    onSave(updatedWeeklyGoals, updatedMonthlyGoals);
    toast(
      (t) => (
        <div className="flex flex-col sm:flex-row items-center justify-between w-full gap-3 sm:gap-4">
          <div className="flex items-center gap-3">
            <div className="bg-red-500/10 p-2 rounded-full">
              <FaTrash className="text-red-500 text-lg" />
            </div>
            <span className="text-red-500 font-medium">
              GOAL DELETED SUCCESSFULLY
            </span>
          </div>
          <button
            onClick={() => toast.dismiss(t.id)}
            className="px-3 py-1 bg-red-500 text-brand-cream rounded-lg hover:bg-red-600 transition-colors mt-2 sm:mt-0 w-full sm:w-auto"
          >
            Okay
          </button>
        </div>
      ),
      {
        position: "top-center",
        duration: 3000,
        style: {
          background: "var(--brand-cream, #Fdf3e3)",
          padding: "16px",
          maxWidth: "95%",
          width: "auto",
          boxShadow: "0 4px 12px rgba(0, 0, 0, 0.1)",
          border: "1px solid rgba(0, 0, 0, 0.05)",
        },
      }
    );
  };

  const handleAddNewGoal = (type: "weekly" | "monthly") => {
    setNewGoalType(type);
    setNewGoalValue("");
  };

  const handleSaveNewGoal = () => {
    if (!newGoalType || !newGoalValue.trim()) return;

    const updatedWeeklyGoals = [...weeklyGoals.map((g) => g.title)];
    const updatedMonthlyGoals = [...monthlyGoals.map((g) => g.title)];

    if (newGoalType === "weekly" && weeklyGoals.length < 5) {
      updatedWeeklyGoals.push(newGoalValue.trim());
    } else if (newGoalType === "monthly" && monthlyGoals.length < 5) {
      updatedMonthlyGoals.push(newGoalValue.trim());
    }

    onSave(updatedWeeklyGoals, updatedMonthlyGoals);
    toast(
      (t) => (
        <div className="flex flex-col sm:flex-row items-center justify-between w-full gap-3 sm:gap-4">
          <div className="flex items-center gap-3">
            <div className="bg-[#4b8052]/10 p-2 rounded-full">
              <FaPlus className="text-[#4b8052] text-lg" />
            </div>
            <span className="text-[#4b8052] font-medium">
              GOAL ADDED SUCCESSFULLY
            </span>
          </div>
          <button
            onClick={() => toast.dismiss(t.id)}
            className="px-3 py-1 bg-[#4b8052] text-white rounded-lg hover:bg-[#4b8052]/90 transition-colors mt-2 sm:mt-0 w-full sm:w-auto"
          >
            Okay
          </button>
        </div>
      ),
      {
        position: "top-center",
        duration: 3000,
        style: {
          background: "var(--brand-cream, #Fdf3e3)",
          padding: "16px",
          maxWidth: "95%",
          width: "auto",
          boxShadow: "0 4px 12px rgba(0, 0, 0, 0.1)",
          border: "1px solid rgba(0, 0, 0, 0.05)",
        },
      }
    );
    setNewGoalType(null);
    setNewGoalValue("");
  };

  const handleCancelEdit = () => {
    setEditingGoal(null);
    setEditValue("");
  };

  const handleCancelNewGoal = () => {
    setNewGoalType(null);
    setNewGoalValue("");
  };

  const handleToggleComplete = (goal: Goal, type: "weekly" | "monthly") => {
    onToggleGoalComplete(goal._id, type);
    if (!goal.isCompleted) {
      const completedCount =
        type === "weekly"
          ? weeklyGoals.filter((g) => g.isCompleted).length + 1
          : monthlyGoals.filter((g) => g.isCompleted).length + 1;
      const totalCount =
        type === "weekly" ? weeklyGoals.length : monthlyGoals.length;
      const remainingCount = totalCount - completedCount;

      toast(
        (t) => (
          <div className="flex flex-col sm:flex-row items-center justify-between w-full gap-3 sm:gap-4">
            <div className="flex items-center gap-3">
              <div className="bg-[#4b8052]/10 p-2 rounded-full">
                <FaStar className="text-[#4b8052] text-lg" />
              </div>
              <div className="flex flex-col">
                <span className="text-[#4b8052] font-medium">
                  GOAL {completedCount} ACHIEVED: {goal.title.toUpperCase()}
                </span>
                <span className="text-[#4b8052]/80 text-sm">
                  {remainingCount} {type.toUpperCase()}{" "}
                  {remainingCount === 1 ? "GOAL" : "GOALS"} REMAINING
                </span>
              </div>
            </div>
            <button
              onClick={() => toast.dismiss(t.id)}
              className="px-3 py-1 text-sm bg-[#4b8052] text-brand-cream rounded hover:bg-[#4b8052]/90 transition-colors w-full sm:w-auto mt-2 sm:mt-0"
            >
              Okay
            </button>
          </div>
        ),
        {
          style: {
            background: "var(--brand-cream, #Fdf3e3)",
            padding: "12px 16px",
            width: "auto",
            maxWidth: "95%",
            boxShadow: "0 4px 12px rgba(0, 0, 0, 0.1)",
            border: "1px solid rgba(0, 0, 0, 0.05)",
          },
          duration: 5000,
        }
      );
    }
  };

  const handleSendTestEmail = async () => {
    try {
      if (!user?.id) {
        toast(
          (t) => (
            <div className="flex items-center justify-between w-full gap-4">
              <span className="text-[#4b8052] font-medium">
                PLEASE LOG IN TO SEND EMAIL SUMMARY
              </span>
              <button
                onClick={() => toast.dismiss(t.id)}
                className="px-3 py-1 text-sm bg-[#4b8052] text-white rounded hover:bg-[#4b8052]/90"
              >
                Okay
              </button>
            </div>
          ),
          {
            style: {
              background: "var(--brand-cream, #Fdf3e3)",
              padding: "12px 16px",
              minWidth: "400px",
              boxShadow: "0 4px 12px rgba(0, 0, 0, 0.1)",
              border: "1px solid rgba(0, 0, 0, 0.05)",
            },
            duration: 5000,
          }
        );
        return;
      }
      const token = await getToken();
      if (!token) return;
      const response = await fetch(
        `${process.env.REACT_APP_API_BASE_URL}/api/goals/send-summary/${user.id}`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (response.ok) {
        toast(
          (t) => (
            <div className="flex items-center justify-between w-full gap-4">
              <span className="text-[#4b8052] font-medium">
                GOAL SUMMARY EMAIL SENT SUCCESSFULLY
              </span>
              <button
                onClick={() => toast.dismiss(t.id)}
                className="px-3 py-1 text-sm bg-[#4b8052] text-white rounded hover:bg-[#4b8052]/90"
              >
                Okay
              </button>
            </div>
          ),
          {
            style: {
              background: "var(--brand-cream, #Fdf3e3)",
              padding: "12px 16px",
              minWidth: "400px",
              boxShadow: "0 4px 12px rgba(0, 0, 0, 0.1)",
              border: "1px solid rgba(0, 0, 0, 0.05)",
            },
            duration: 5000,
          }
        );
      } else {
        throw new Error("Failed to send email");
      }
    } catch (error) {
      console.error("Error sending email summary:", error);
      toast(
        (t) => (
          <div className="flex items-center justify-between w-full gap-4">
            <span className="text-[#4b8052] font-medium">
              FAILED TO SEND EMAIL
            </span>
            <button
              onClick={() => toast.dismiss(t.id)}
              className="px-3 py-1 text-sm bg-[#4b8052] text-white rounded hover:bg-[#4b8052]/90"
            >
              Okay
            </button>
          </div>
        ),
        {
          style: {
            background: "var(--brand-cream, #Fdf3e3)",
            padding: "12px 16px",
            minWidth: "400px",
            boxShadow: "0 4px 12px rgba(0, 0, 0, 0.1)",
            border: "1px solid rgba(0, 0, 0, 0.05)",
          },
          duration: 5000,
        }
      );
    }
  };

  const handleClearWeeklyGoals = () => {
    onSave(
      [],
      monthlyGoals.map((g) => g.title)
    );
    toast(
      (t) => (
        <div className="flex flex-col sm:flex-row items-center justify-between w-full gap-3 sm:gap-4">
          <div className="flex items-center gap-3">
            <div className="bg-red-500/10 p-2 rounded-full">
              <FaTrash className="text-red-500 text-lg" />
            </div>
            <span className="text-red-500 font-medium">
              ALL WEEKLY GOALS CLEARED
            </span>
          </div>
          <button
            onClick={() => toast.dismiss(t.id)}
            className="px-3 py-1 bg-red-500 text-white rounded-lg hover:bg-red-600 transition-colors mt-2 sm:mt-0 w-full sm:w-auto"
          >
            Okay
          </button>
        </div>
      ),
      {
        position: "top-center",
        duration: 3000,
        style: {
          background: "var(--brand-cream, #Fdf3e3)",
          padding: "16px",
          maxWidth: "95%",
          width: "auto",
          boxShadow: "0 4px 12px rgba(0, 0, 0, 0.1)",
          border: "1px solid rgba(0, 0, 0, 0.05)",
        },
      }
    );
  };

  const handleClearMonthlyGoals = () => {
    onSave(
      weeklyGoals.map((g) => g.title),
      []
    );
    toast(
      (t) => (
        <div className="flex flex-col sm:flex-row items-center justify-between w-full gap-3 sm:gap-4">
          <div className="flex items-center gap-3">
            <div className="bg-red-500/10 p-2 rounded-full">
              <FaTrash className="text-red-500 text-lg" />
            </div>
            <span className="text-red-500 font-medium">
              ALL MONTHLY GOALS CLEARED
            </span>
          </div>
          <button
            onClick={() => toast.dismiss(t.id)}
            className="px-3 py-1 bg-red-500 text-brand-cream rounded-lg hover:bg-red-600 transition-colors mt-2 sm:mt-0 w-full sm:w-auto"
          >
            Okay
          </button>
        </div>
      ),
      {
        position: "top-center",
        duration: 3000,
        style: {
          background: "var(--brand-cream, #Fdf3e3)",
          padding: "16px",
          maxWidth: "95%",
          width: "auto",
          boxShadow: "0 4px 12px rgba(0, 0, 0, 0.1)",
          border: "1px solid rgba(0, 0, 0, 0.05)",
        },
      }
    );
  };

  if (!isEnabled) {
    return (
      <div className="flex items-center justify-between p-3 bg-brand-green-dark/50 rounded-lg mb-8">
        <div className="flex items-center">
          <span className="text-brand-cream/60 text-[14px]">
            Toggle to enable goals tracking
          </span>
        </div>
        <div className="flex items-center gap-2">
          <span className="text-brand-cream/50 text-sm">Off</span>
          <Switch
            checked={isEnabled}
            onChange={onToggleGoals}
            className={`${
              isEnabled ? "bg-brand-orange-cream" : "bg-brand-cream/20"
            } relative inline-flex h-6 w-11 items-center rounded-full transition-colors focus:outline-none`}
          >
            <span
              className={`${
                isEnabled ? "translate-x-6" : "translate-x-1"
              } inline-block h-4 w-4 transform rounded-full bg-brand-cream/60 transition-transform`}
            />
          </Switch>
        </div>
      </div>
    );
  }

  return (
    <div className="bg-brand-green-dark/50 rounded-lg px-4 mb-8 shadow-lg pb-4 pt-4 lg:pt-0">
      <div className="flex flex-col space-y-4">
        {/* First row - Title and Controls (Mobile) */}
        <div className="flex sm:hidden items-center justify-between">
          {/* Left - Title */}
          <div className="flex flex-col">
            <span className="text-brand-cream text-sm font-medium">
              Goal Tracking
            </span>
          </div>

          {/* Right - Controls */}
          <div className="flex items-center gap-3">
            {/* Email button */}
            <button
              onClick={handleSendTestEmail}
              className="p-2 text-brand-cream hover:text-brand-orange-cream transition-colors"
              title="Email Summary"
            >
              <FaEnvelope size={16} />
            </button>

            {/* Toggle switch */}
            <div className="flex items-center gap-2">
              <span className="text-brand-cream text-sm font-medium">On</span>
              <Switch
                checked={isEnabled}
                onChange={onToggleGoals}
                className={`${
                  isEnabled ? "bg-brand-cream/50" : "bg-brand-cream/20"
                } relative inline-flex h-6 w-11 items-center rounded-full transition-colors focus:outline-none shadow-sm`}
              >
                <span
                  className={`${
                    isEnabled ? "translate-x-6" : "translate-x-1"
                  } inline-block h-4 w-4 transform rounded-full bg-brand-cream transition-transform shadow-sm`}
                />
              </Switch>
            </div>
          </div>
        </div>

        {/* Desktop version of the header */}
        <div className="hidden sm:flex sm:flex-row items-start sm:items-center justify-between gap-4 sm:gap-0">
          {/* Left section - Title */}
          <div className="flex flex-col">
            <span className="text-brand-cream text-sm font-medium">
              Goal Tracking
            </span>
            <span className="text-brand-cream/60 text-[14px]">
              Track your progress
            </span>
          </div>

          {/* Center section - Goals */}
          <div className="flex items-center gap-8">
            {/* Weekly goals */}
            <div className="flex items-center gap-3">
              <span className="text-brand-cream text-sm font-medium min-w-[60px]">
                Weekly
              </span>
              <div className="flex space-x-2">
                {renderGoalIcons(weeklyGoals)}
              </div>
            </div>

            {/* Monthly goals */}
            <div className="flex items-center gap-3">
              <span className="text-brand-cream text-sm font-medium min-w-[60px]">
                Monthly
              </span>
              <div className="flex space-x-2">
                {renderGoalIcons(monthlyGoals)}
              </div>
            </div>
          </div>

          {/* Right section - Controls */}
          <div className="flex items-center gap-4">
            {/* Email button */}
            <button
              onClick={handleSendTestEmail}
              className="p-2 text-brand-cream hover:text-brand-orange-cream transition-colors"
              title="Email Summary"
            >
              <FaEnvelope size={16} />
            </button>

            {/* Toggle switch */}
            <div className="flex items-center gap-2">
              <span className="text-brand-cream text-sm font-medium">On</span>
              <Switch
                checked={isEnabled}
                onChange={onToggleGoals}
                className={`${
                  isEnabled ? "bg-brand-cream/50" : "bg-brand-cream/20"
                } relative inline-flex h-6 w-11 items-center rounded-full transition-colors focus:outline-none shadow-sm`}
              >
                <span
                  className={`${
                    isEnabled ? "translate-x-6" : "translate-x-1"
                  } inline-block h-4 w-4 transform rounded-full bg-brand-cream transition-transform shadow-sm`}
                />
              </Switch>
            </div>

            {/* Expand/Collapse button */}
            <button
              onClick={() => setIsExpanded(!isExpanded)}
              className="flex items-center gap-2 text-brand-cream hover:text-brand-orange-cream transition-colors"
            >
              <span className="text-sm font-medium">
                {isExpanded ? "Collapse" : "Expand"}
              </span>
              {isExpanded ? (
                <FaChevronUp size={14} />
              ) : (
                <FaChevronDown size={14} />
              )}
            </button>
          </div>
        </div>

        {/* Mobile Goals Section */}
        <div className="flex sm:hidden flex-col gap-4">
          {/* Weekly goals */}
          <div className="flex items-center gap-2">
            <span className="text-brand-cream text-sm font-medium min-w-[50px]">
              Weekly
            </span>
            <div className="flex space-x-1.5 flex-1">
              {renderGoalIcons(weeklyGoals)}
            </div>
          </div>

          {/* Monthly goals */}
          <div className="flex items-center gap-2">
            <span className="text-brand-cream text-sm font-medium min-w-[50px]">
              Monthly
            </span>
            <div className="flex space-x-1.5 flex-1">
              {renderGoalIcons(monthlyGoals)}
            </div>
          </div>

          {/* Expand/Collapse button */}
          <div className="flex justify-end">
            <button
              onClick={() => setIsExpanded(!isExpanded)}
              className="flex items-center gap-2 text-brand-cream hover:text-brand-orange-cream transition-colors"
            >
              <span className="text-sm font-medium">
                {isExpanded ? "Collapse" : "Expand"}
              </span>
              {isExpanded ? (
                <FaChevronUp size={14} />
              ) : (
                <FaChevronDown size={14} />
              )}
            </button>
          </div>
        </div>

        {/* Expanded Goals List */}
        {isExpanded && (
          <>
            <div className="h-px bg-brand-cream/10 w-full" />
            <div className="mt-4 grid grid-cols-1 md:grid-cols-2 gap-6">
              {/* Weekly Goals List */}
              <div>
                <h3 className="text-brand-cream text-sm mb-2">Weekly Goals</h3>
                <div className="space-y-2">
                  {weeklyGoals.map((goal) => (
                    <div
                      key={goal._id}
                      className="flex items-center gap-3 text-brand-cream border-b border-brand-cream/20 pb-2"
                    >
                      <input
                        type="checkbox"
                        checked={goal.isCompleted}
                        onChange={() => handleToggleComplete(goal, "weekly")}
                        className="w-4 h-4 rounded border-brand-cream/50 text-brand-logo focus:ring-brand-logo/50 accent-brand-logo"
                      />
                      {editingGoal?.id === goal._id ? (
                        <div className="flex-1 flex items-center gap-2">
                          <input
                            type="text"
                            value={editValue}
                            onChange={(e) => setEditValue(e.target.value)}
                            onKeyDown={(e) => {
                              if (e.key === "Enter" && editValue.trim()) {
                                handleSaveEdit();
                              }
                            }}
                            className="flex-1 bg-transparent border-b border-brand-cream text-brand-cream focus:outline-none"
                            autoFocus
                          />
                          <button
                            onClick={handleSaveEdit}
                            className="text-brand-logo hover:text-brand-logo/80"
                          >
                            <FaCheck />
                          </button>
                          <button
                            onClick={handleCancelEdit}
                            className="text-red-500 hover:text-red-400"
                          >
                            <FaTimes />
                          </button>
                        </div>
                      ) : (
                        <>
                          <span
                            onClick={() => handleStartEdit(goal, "weekly")}
                            className={`flex-1 cursor-pointer ${
                              goal.isCompleted
                                ? "line-through text-brand-cream/50"
                                : ""
                            }`}
                          >
                            {goal.title}
                          </span>
                          <div className="hidden md:flex items-center gap-2">
                            <button
                              onClick={() => handleStartEdit(goal, "weekly")}
                              className="px-2 py-1 text-xs bg-brand-cream/10 text-brand-cream hover:bg-brand-cream/20 rounded"
                            >
                              Edit
                            </button>
                            <button
                              onClick={() =>
                                handleDeleteGoal(goal._id, "weekly")
                              }
                              className="px-2 py-1 text-xs bg-red-500/20 text-red-500 hover:bg-red-500/20 rounded"
                            >
                              Delete
                            </button>
                          </div>
                          <div className="flex md:hidden items-center gap-2">
                            <button
                              onClick={() => handleStartEdit(goal, "weekly")}
                              className="text-brand-cream/50 hover:text-brand-cream"
                            >
                              <FaPencilAlt size={14} />
                            </button>
                            <button
                              onClick={() =>
                                handleDeleteGoal(goal._id, "weekly")
                              }
                              className="text-red-500 hover:text-red-500"
                            >
                              <FaTimes size={14} />
                            </button>
                          </div>
                        </>
                      )}
                    </div>
                  ))}
                  {weeklyGoals.length < 5 && !newGoalType && (
                    <button
                      onClick={() => handleAddNewGoal("weekly")}
                      className="flex items-center gap-2 text-brand-cream/50 hover:text-brand-cream text-sm mt-2"
                    >
                      <FaPlus size={12} />
                      <span>Add another goal</span>
                    </button>
                  )}
                  {newGoalType === "weekly" && (
                    <div className="flex items-center gap-2 mt-2">
                      <input
                        type="text"
                        value={newGoalValue}
                        onChange={(e) => setNewGoalValue(e.target.value)}
                        onKeyDown={(e) => {
                          if (e.key === "Enter" && newGoalValue.trim()) {
                            handleSaveNewGoal();
                          }
                        }}
                        className="flex-1 bg-transparent border-b border-brand-cream text-brand-cream focus:outline-none"
                        placeholder="Enter new goal"
                        autoFocus
                      />
                      <button
                        onClick={handleSaveNewGoal}
                        className="text-brand-logo hover:text-brand-logo/80"
                      >
                        <FaCheck />
                      </button>
                      <button
                        onClick={handleCancelNewGoal}
                        className="text-red-500 hover:text-red-400"
                      >
                        <FaTimes />
                      </button>
                    </div>
                  )}
                </div>
                <div className="mt-4">
                  <button
                    onClick={handleClearWeeklyGoals}
                    className="px-3 py-1 text-sm bg-brand-cream/10 text-brand-cream/70 hover:bg-brand-cream/20 rounded flex items-center gap-2"
                  >
                    <FaTrash size={12} />
                    Clear Weekly Goals
                  </button>
                </div>
              </div>

              {/* Monthly Goals List */}
              <div>
                <h3 className="text-brand-cream text-sm mb-2">Monthly Goals</h3>
                <div className="space-y-2">
                  {monthlyGoals.map((goal) => (
                    <div
                      key={goal._id}
                      className="flex items-center gap-3 text-brand-cream border-b border-brand-cream/20 pb-2"
                    >
                      <input
                        type="checkbox"
                        checked={goal.isCompleted}
                        onChange={() => handleToggleComplete(goal, "monthly")}
                        className="w-4 h-4 rounded border-brand-cream/50 text-brand-logo focus:ring-brand-logo/50 accent-brand-logo"
                      />
                      {editingGoal?.id === goal._id ? (
                        <div className="flex-1 flex items-center gap-2">
                          <input
                            type="text"
                            value={editValue}
                            onChange={(e) => setEditValue(e.target.value)}
                            onKeyDown={(e) => {
                              if (e.key === "Enter" && editValue.trim()) {
                                handleSaveEdit();
                              }
                            }}
                            className="flex-1 bg-transparent border-b border-brand-cream text-brand-cream focus:outline-none"
                            autoFocus
                          />
                          <button
                            onClick={handleSaveEdit}
                            className="text-brand-logo hover:text-brand-logo/80"
                          >
                            <FaCheck />
                          </button>
                          <button
                            onClick={handleCancelEdit}
                            className="text-red-500 hover:text-red-400"
                          >
                            <FaTimes />
                          </button>
                        </div>
                      ) : (
                        <>
                          <span
                            onClick={() => handleStartEdit(goal, "monthly")}
                            className={`flex-1 cursor-pointer ${
                              goal.isCompleted
                                ? "line-through text-brand-cream/50"
                                : ""
                            }`}
                          >
                            {goal.title}
                          </span>
                          <div className="hidden md:flex items-center gap-2">
                            <button
                              onClick={() => handleStartEdit(goal, "monthly")}
                              className="px-2 py-1 text-xs bg-brand-cream/10 text-brand-cream hover:bg-brand-cream/20 rounded"
                            >
                              Edit
                            </button>
                            <button
                              onClick={() =>
                                handleDeleteGoal(goal._id, "monthly")
                              }
                              className="px-2 py-1 text-xs bg-red-500/20 text-red-500 hover:bg-red-500/20 rounded"
                            >
                              Delete
                            </button>
                          </div>
                          <div className="flex md:hidden items-center gap-2">
                            <button
                              onClick={() => handleStartEdit(goal, "monthly")}
                              className="text-brand-cream/50 hover:text-brand-cream"
                            >
                              <FaPencilAlt size={14} />
                            </button>
                            <button
                              onClick={() =>
                                handleDeleteGoal(goal._id, "monthly")
                              }
                              className="text-red-500 hover:text-red-500"
                            >
                              <FaTimes size={14} />
                            </button>
                          </div>
                        </>
                      )}
                    </div>
                  ))}
                  {monthlyGoals.length < 5 && !newGoalType && (
                    <button
                      onClick={() => handleAddNewGoal("monthly")}
                      className="flex items-center gap-2 text-brand-cream/50 hover:text-brand-cream text-sm mt-2"
                    >
                      <FaPlus size={12} />
                      <span>Add another goal</span>
                    </button>
                  )}
                  {newGoalType === "monthly" && (
                    <div className="flex items-center gap-2 mt-2">
                      <input
                        type="text"
                        value={newGoalValue}
                        onChange={(e) => setNewGoalValue(e.target.value)}
                        onKeyDown={(e) => {
                          if (e.key === "Enter" && newGoalValue.trim()) {
                            handleSaveNewGoal();
                          }
                        }}
                        className="flex-1 bg-transparent border-b border-brand-cream text-brand-cream focus:outline-none"
                        placeholder="Enter new goal"
                        autoFocus
                      />
                      <button
                        onClick={handleSaveNewGoal}
                        className="text-brand-logo hover:text-brand-logo/80"
                      >
                        <FaCheck />
                      </button>
                      <button
                        onClick={handleCancelNewGoal}
                        className="text-red-500 hover:text-red-400"
                      >
                        <FaTimes />
                      </button>
                    </div>
                  )}
                </div>
                <div className="mt-4">
                  <button
                    onClick={handleClearMonthlyGoals}
                    className="px-3 py-1 text-sm bg-brand-cream/10 text-brand-cream/70 hover:bg-brand-cream/20 rounded flex items-center gap-2"
                  >
                    <FaTrash size={12} />
                    Clear Monthly Goals
                  </button>
                </div>
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default GoalsSection;
