import BlueGradient from "../../Images/Gradient2.svg";
import MenuBar from "../../Menu/MenuBar";
import { SignUp, useUser } from "@clerk/clerk-react";
import { useNavigate } from "react-router-dom";
import React from "react";

const signupFormStyle = {
  backgroundImage: `url(${BlueGradient})`,
  backgroundSize: "cover",
};

const OnboardingSteps = () => (
  <div className="fixed top-10 left-1/2 -translate-x-1/2 z-10 w-[95%] sm:w-auto">
    <div className="flex items-center space-x-2 sm:space-x-4 scale-75 sm:scale-100 origin-center">
      <div className="flex items-center">
        <div className="w-6 h-6 rounded-full bg-brand-cream text-brand-green flex items-center justify-center text-sm font-medium">
          1
        </div>
        <span className="ml-2 text-brand-cream text-sm">Account</span>
      </div>
      <div className="w-6 sm:w-8 h-[2px] bg-brand-cream opacity-50 mt-[1px]" />
      <div className="flex items-center opacity-70">
        <div className="w-6 h-6 rounded-full border-2 border-brand-cream text-brand-cream flex items-center justify-center text-sm">
          2
        </div>
        <span className="ml-2 text-brand-cream text-sm">Subscription</span>
      </div>
      <div className="w-6 sm:w-8 h-[2px] bg-brand-cream opacity-70 mt-[1px]" />
      <div className="flex items-center opacity-70">
        <div className="w-6 h-6 rounded-full border-2 border-brand-cream text-brand-cream flex items-center justify-center text-sm">
          3
        </div>
        <span className="ml-2 text-brand-cream text-sm">Interview</span>
      </div>
      <div className="w-6 sm:w-8 h-[2px] bg-brand-cream opacity-70 mt-[1px]" />
      <div className="flex items-center opacity-70">
        <div className="w-6 h-6 rounded-full border-2 border-brand-cream text-brand-cream flex items-center justify-center text-sm">
          4
        </div>
        <span className="ml-2 text-brand-cream text-sm">Start</span>
      </div>
    </div>
  </div>
);

function SignUpPage() {
  const { isSignedIn } = useUser();
  const navigate = useNavigate();

  if (isSignedIn) {
    navigate("/dashboard");
  }

  return (
    <div className="min-h-screen overflow-x-hidden" style={signupFormStyle}>
      <div>
        <MenuBar />
      </div>
      <OnboardingSteps />
      <div className="max-w-[1440px] mx-auto px-4">
        <div className="w-full min-h-screen flex flex-col lg:flex-row justify-center items-center">
          {/* Left Col */}
          <div className="w-full lg:w-1/2 h-full flex flex-col justify-center items-center">
            {/* desktop view */}
            <div className="w-1/2 hidden lg:block">
              <h1 className="leading-none text-left ml-2 text-brand-logo">
                <span className="leading-none text-left ml-2 text-9xl">
                  sloane<span className="font-Raleway -ml-2">.</span>
                </span>
              </h1>
            </div>
          </div>

          {/* Right Col signup*/}
          <div className="w-full lg:w-1/2 flex flex-col justify-center items-center relative">
            <div className="w-full px-4 mr-6">
              <SignUp
                redirectUrl="/subscription"
                appearance={{
                  elements: {
                    rootBox: "mx-auto mt-20",
                    card: "rounded-lg shadow-sm",
                  },
                }}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default SignUpPage;
