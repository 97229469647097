import React from "react";
import {
  FaLongArrowAltLeft,
  FaLongArrowAltRight,
  FaHandPointDown,
} from "react-icons/fa";
import styled, { keyframes } from "styled-components";

const swipe = keyframes`
  0% { transform: translateX(0); }
  10% { transform: translateX(-0.1em); }
  20% { transform: translateX(0.1em); }
  30% { transform: translateX(-0.1em); }
  40% { transform: translateX(0.1em); }
  50% { transform: translateX(0); }
  100% { transform: translateX(0); }
`;

const swipeRotate = keyframes`
  0% { transform: rotate(0); }
  10% { transform: rotate(-5deg); }
  20% { transform: rotate(5deg); }
  30% { transform: rotate(-5deg); }
  40% { transform: rotate(5deg); }
  50% { transform: rotate(0); }
  100% { transform: rotate(0); }
`;

const SwipeContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 0.5em;
  color: rgba(230, 220, 203, 0.7);
  animation: ${swipe} 2.7s linear infinite;
  animation-delay: 250ms;
`;

const ArrowLeft = styled(FaLongArrowAltLeft)`
  font-size: 1em;
  color: rgba(230, 220, 203, 0.5);
`;

const ArrowRight = styled(FaLongArrowAltRight)`
  font-size: 1em;
  color: rgba(230, 220, 203, 0.5);
`;

const HandPointer = styled(FaHandPointDown)`
  font-size: 1em;
  animation: ${swipeRotate} 2.7s linear infinite;
  animation-delay: 250ms;
`;

const SwipeIndicator: React.FC = () => {
  return (
    <SwipeContainer>
      <ArrowLeft />
      <HandPointer />
      <ArrowRight />
    </SwipeContainer>
  );
};

export default SwipeIndicator;
