import React from "react";
import { BrowserRouter as Router } from "react-router-dom";
import { ClerkProvider } from "@clerk/clerk-react";
import { ChatProvider } from "./context/ChatContext";
import { ThemeProvider } from "./components/Pages/chat/ThemeContext";
import { PromptProvider } from "./context/PromptContext";
import AppContent from "./AppContent";
import { AssistantProvider } from "./context/assistantContext";
import { FolderProvider } from "./context/FolderContext";
import { TimerProvider } from "./context/TimerContext";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";

const App: React.FC = () => {
  const queryClient = new QueryClient();

  return (
    <ThemeProvider>
      <ClerkProvider
        publishableKey={process.env.REACT_APP_CLERK_PUBLISHABLE_KEY!}
      >
        <TimerProvider>
          <QueryClientProvider client={queryClient}>
            <AssistantProvider>
              <FolderProvider>
                <ChatProvider>
                  <Router>
                    <PromptProvider>
                      <AppContent />
                    </PromptProvider>
                  </Router>
                </ChatProvider>
              </FolderProvider>
            </AssistantProvider>
          </QueryClientProvider>
        </TimerProvider>
      </ClerkProvider>
    </ThemeProvider>
  );
};

export default App;
