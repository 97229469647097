import React from "react";
import Timer from "./Timer";
import { useTimer } from "../../context/TimerContext";

const TimerWrapper: React.FC = () => {
  const { isTimerVisible, setIsTimerVisible, timerConfig } = useTimer();

  if (!isTimerVisible) return null;

  return (
    <div className="relative">
      <Timer
        workMinutes={timerConfig.workMinutes}
        breakMinutes={timerConfig.breakMinutes}
        onStop={() => setIsTimerVisible(false)}
        onComplete={() => {
          // Handle timer completion
          setIsTimerVisible(false);
        }}
      />
    </div>
  );
};

export default TimerWrapper;
