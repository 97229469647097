import React from "react";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import {
  FaLinkedin,
  FaTwitter,
  FaInstagram,
  FaFacebook,
  FaPinterest,
  FaGlobe,
} from "react-icons/fa";
import { IconType } from "react-icons";
import { CommunityMember } from "../../../types/community";

interface FullBioModalProps {
  open: boolean;
  onClose: () => void;
  member: CommunityMember;
}

const FullBioModal: React.FC<FullBioModalProps> = ({
  open,
  onClose,
  member,
}) => {
  const getSocialIcon = (
    url: string | undefined,
    Icon: IconType,
    label: string
  ) => {
    if (!url) return null;
    return (
      <a
        href={url}
        target="_blank"
        rel="noopener noreferrer"
        className="text-brand-cream hover:text-brand-cream/80 transition-colors"
        aria-label={label}
      >
        <Icon size={24} />
      </a>
    );
  };

  const BADGE_STYLE = {
    bg: "bg-brand-yellow",
    text: "text-brand-green-dark/80",
  };

  return (
    <Modal open={open} onClose={onClose}>
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: 900,
          maxHeight: "90vh",
          bgcolor: "#4b8052",
          boxShadow: 24,
          borderRadius: 2,
          p: 4,
          display: "flex",
          flexDirection: "column",
        }}
      >
        <div className="flex-1 overflow-y-auto [&::-webkit-scrollbar]:w-[3px] [&::-webkit-scrollbar-track]:bg-transparent [&::-webkit-scrollbar-thumb]:bg-brand-cream">
          <div className="relative min-h-full">
            <div className="flex gap-8">
              {/* Left Column */}
              <div className="w-1/3 flex flex-col">
                {/* Profile Image */}
                <div className="w-48 h-48 mx-auto rounded-full border-4 border-brand-cream shadow-lg overflow-hidden bg-brand-green/10 flex items-center justify-center">
                  <span className="text-6xl font-bold text-brand-cream">
                    IT
                  </span>
                </div>

                {/* Basic Info */}
                <div className="mt-6 text-brand-cream">
                  <h2 className="text-2xl font-semibold mb-2">
                    {member.communityDetails.preferredName || member.name}
                  </h2>
                  <h3 className="text-xl mb-4">
                    Business Name:{" "}
                    <span className="font-light">
                      {member.businessProfile?.businessName ??
                        "No business name"}
                    </span>
                  </h3>

                  {/* Location */}
                  {(member.communityDetails.state ||
                    member.communityDetails.country) && (
                    <p className="text-sm mb-4">
                      {[
                        member.communityDetails.state,
                        member.communityDetails.country,
                      ]
                        .filter(Boolean)
                        .join(", ")}
                    </p>
                  )}

                  {/* Business Types */}
                  <div className="flex flex-wrap gap-2 mb-4">
                    {member.businessProfile?.businessTypes?.map((type) => (
                      <span
                        key={type}
                        className={`px-2 py-0.5 rounded-md text-xs ${BADGE_STYLE.bg} ${BADGE_STYLE.text}`}
                      >
                        {type}
                      </span>
                    ))}
                  </div>

                  {/* Fun Fact */}
                  {member.communityDetails.funFact && (
                    <div className="mb-4">
                      <h4 className="text-sm font-semibold mb-1">Fun Fact</h4>
                      <p className="text-sm italic">
                        "{member.communityDetails.funFact}"
                      </p>
                    </div>
                  )}

                  {/* Social Links */}
                  <div className="flex gap-4 mt-4">
                    {getSocialIcon(
                      member.communityDetails.linkedinUrl,
                      FaLinkedin,
                      "LinkedIn"
                    )}
                    {getSocialIcon(
                      member.communityDetails.twitterUrl,
                      FaTwitter,
                      "Twitter"
                    )}
                    {getSocialIcon(
                      member.communityDetails.instagramUrl,
                      FaInstagram,
                      "Instagram"
                    )}
                    {getSocialIcon(
                      member.communityDetails.facebookUrl,
                      FaFacebook,
                      "Facebook"
                    )}
                    {getSocialIcon(
                      member.communityDetails.pinterestUrl,
                      FaPinterest,
                      "Pinterest"
                    )}
                    {getSocialIcon(
                      member.communityDetails.websiteUrl,
                      FaGlobe,
                      "Website"
                    )}
                  </div>
                </div>
              </div>

              {/* Right Column */}
              <div className="w-2/3 text-brand-cream">
                {/* Biography Section */}
                <section className="mb-6">
                  <h3 className="text-xl font-semibold mb-2">About Me</h3>
                  <p className="text-sm">{member.communityDetails.biography}</p>
                </section>

                {/* Business Section */}
                <section className="mb-6">
                  <h3 className="text-xl font-semibold mb-2">
                    About My Business
                  </h3>
                  <p className="text-sm">
                    {member.communityDetails.businessDescription}
                  </p>
                </section>

                {/* Contact Preferences */}
                {member.communityDetails.openToMessages && (
                  <section className="mb-6">
                    <h3 className="text-xl font-semibold mb-2">
                      Contact Preferences
                    </h3>
                    <p className="text-sm">
                      Open to receiving messages from community members
                    </p>
                  </section>
                )}
              </div>
            </div>

            {/* Close Button - moved outside scroll area */}
            <button
              onClick={onClose}
              className="absolute top-0 right-0 text-brand-cream hover:text-brand-cream/80 transition-colors text-2xl"
            >
              ×
            </button>
          </div>
        </div>
      </Box>
    </Modal>
  );
};

export default FullBioModal;
