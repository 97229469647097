// import Tile from "./Tile";
import VideoTile from "./VideoTile";
// import Blogs from "./Files/Blogs.pdf";
// import EmailFreebie from "./Files/EmailFreebie.pdf";
// import NewOffering from "./Files/NewOffering.pdf";
// import NewStaff from "./Files/NewStaff.pdf";
// import email from './Images/email.png';
// import marketing from './Images/marketing.png';
import { useQuery } from "@tanstack/react-query";
import { VideoService } from "../../../services/videoService";
import { QUERY_KEYS } from "../../../constants/queryKeys";
import LoadingSpinner from "../Dashboard/LoadingSpinner";
import Sidebar from "../../Sidebar/Sidebar";

// Array of objects for each PDF
// const pdfFiles = [
//     { title: "Increase Social Audience", path: Blogs, description: "Streamline your marketing with one powerful message across all platforms! Transform a single blog post into consistent, engaging content for social media, website clicks, and email campaigns. Save time and maintain message consistency across every audience." },
//     { title: "Email Database Growth", path: EmailFreebie, description: "Brainstorm & then transform ideas into action with the Free Download Workflow. Create irresistible freebies for your Home Page, market them on social media, and grow your email data-base while collecting valuable leads." },
//     { title: "Create New Offering", path: NewOffering, description: "Increase your revenue stream with the New Offerings Workflow. From concept to launch, effortlessly create and market new products and paid services. Maximize your income potential and expand your business." },
//     { title: "Training New Staff", path: NewStaff, description: "Wanting to expand? Streamline the process of training, interviewing, and hiring new staff. Simplify expansion efforts and ensure seamless team integration." }
// ];

function Resources() {
  const {
    data: videos,
    isLoading,
    error,
  } = useQuery({
    queryKey: [QUERY_KEYS.VIDEOS.GET_ALL],
    queryFn: () => VideoService.getVideos(),
  });

  return (
    <div className="w-full min-h-screen bg-brand-green">
      <Sidebar
        onNewAssistant={() => {}}
        onUpdateModel={() => {}}
        onOpenMindfulness={() => {}}
        onOpenPomodoro={() => {}}
      />

      <div className="lg:pl-[88px] xl:pl-[248px] transition-all duration-300">
        <div className="p-6 pt-0">
          <div className="col-span-2 sm:col-span-2 md:col-span-3 lg:col-span-4 pt-20 md:pt-14 xl:pl-8">
            <h2 className="text-brand-cream text-[26px] md:text-3xl lg:text-5xl xl:text-5xl font-bold mb-8">
              How-To Video Library
            </h2>
          </div>

          {/* Container for PDF Tiles */}
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4 md:gap-8 lg:gap-6 mb-8">
            {/* {pdfFiles.map(pdf => (
              <div key={pdf.title} className="w-[150px] h-[120px] md:w-[150px] lg:w-[200px] md:h-[150px] lg:h-[150px] relative">
                <Tile title={pdf.title} description={<div dangerouslySetInnerHTML={{__html: pdf.description}} />} link={pdf.path} />
              </div>
            ))} */}
          </div>

          {isLoading ? (
            <div className="flex justify-center">
              <LoadingSpinner />
            </div>
          ) : error ? (
            <div className="text-center text-brand-cream">
              Error loading videos
            </div>
          ) : (
            <div className="flex justify-center w-full xl:pl-8">
              <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-8 justify-items-center">
                {videos?.map((video) => (
                  <div key={video._id} className="w-full max-w-[350px]">
                    <VideoTile
                      title={video.title}
                      videoUrl={video.videoUrl}
                      description={video.description}
                    />
                  </div>
                ))}
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default Resources;
