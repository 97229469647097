import { CommunityMember } from "../types/community";

interface OptInFormData {
	name: string;
	biography: string;
	businessDescription: string;
	businessTypes: string[];
	websiteUrl: string;
	funFact: string;
	linkedinUrl: string;
	twitterUrl: string;
	instagramUrl: string;
	facebookUrl: string;
	pinterestUrl: string;
	openToMessages: boolean;
	country: string;
	state: string;
}

const BASE_URL = process.env.REACT_APP_API_BASE_URL;

const getCommunityStatus = async (
	token: string,
	userId: string,
): Promise<boolean> => {
	try {
		const response = await fetch(`${BASE_URL}/api/community/status/${userId}`, {
			headers: {
				"Content-Type": "application/json",
				Authorization: `Bearer ${token}`,
			},
		});
		if (!response.ok) {
			throw new Error("Failed to get community status");
		}
		const data = await response.json();
		return data.isCommunityOptedIn;
	} catch (error) {
		console.error("Error checking community status:", error);
		throw error;
	}
};

const getCommunityMembers = async (
	token: string,
): Promise<CommunityMember[]> => {
	try {
		const response = await fetch(
			`${BASE_URL}/api/community/community-members`,
			{
				headers: {
					"Content-Type": "application/json",
					Authorization: `Bearer ${token}`,
				},
			},
		);
		if (!response.ok) {
			throw new Error("Failed to get community members");
		}
		const data = await response.json();
		return data;
	} catch (error) {
		console.error("Error fetching community members:", error);
		throw error;
	}
};

const optInToCommunity = async (
	token: string,
	userId: string,
	formData: OptInFormData,
): Promise<void> => {
	try {
		console.log("Sending opt-in request with data:", formData);
		console.log("To URL:", `${BASE_URL}/api/community/opt-in/${userId}`);
		
		const response = await fetch(`${BASE_URL}/api/community/opt-in/${userId}`, {
			method: "POST",
			headers: {
				"Content-Type": "application/json",
				Authorization: `Bearer ${token}`,
			},
			body: JSON.stringify(formData),
		});

		console.log("Response status:", response.status);

		if (!response.ok) {
			const errorData = await response.json();
			console.log("Error response:", errorData);
			throw new Error(errorData.error || "Failed to opt in to community");
		}
	} catch (error) {
		console.error("Error opting in:", error);
		throw error;
	}
};

export const communityService = {
	getCommunityStatus,
	getCommunityMembers,
	optInToCommunity,
};
