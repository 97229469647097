import { Link } from "react-router-dom";
import Square from "./Dashboard/Square";
import { useUser } from "@clerk/clerk-react";
import NotAdmin from "./NotAdmin";

function Admin() {
  const { user } = useUser();

  if (user?.publicMetadata.account !== "admin") {
    return <NotAdmin />;
  }

  const squareDiv =
    "w-[160px] aspect-square md:w-[200px] lg:w-[220px] relative transition-all duration-300 hover:scale-105 h-[100px] sm:h-[110px] md:h-[120px]";

  return (
    <div className="min-h-screen bg-brand-green">
      <div className="max-w-[1440px] mx-auto px-4 sm:px-6 lg:px-8 py-8">
        {/* Header Section */}
        <div className="text-center mb-12">
          <h2 className="text-brand-cream text-4xl md:text-6xl lg:text-7xl xl:text-8xl font-bold mb-8 transition-all duration-300">
            Founders Lounge
          </h2>
          <div className="space-y-2">
            <h5 className="font-Black text-brand-cream text-[24px] md:text-4xl leading-none">
              Hi {user?.firstName},
            </h5>
            <h5 className="font-Black text-brand-cream text-[21px] md:text-3xl leading-none">
              What do you need today?
            </h5>
          </div>
        </div>

        {/* Grid Section */}
        <div className="grid grid-cols-2 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-6 md:gap-8 justify-items-center">
          <div className={squareDiv}>
            <Link to="/userform">
              <Square title="Create User" />
            </Link>
          </div>
          <div className={squareDiv}>
            <Link to="/assistantform">
              <Square title="Create Ai Assistant" />
            </Link>
          </div>
          <div className={squareDiv}>
            <Link to="/updateAssistant">
              <Square title="Update Ai Assistant" />
            </Link>
          </div>
          <div className={squareDiv}>
            <Link to="/allUsers">
              <Square title="All users" />
            </Link>
          </div>
          <div className={squareDiv}>
            <Link to="/create-prompt">
              <Square title="Create Prompt" />
            </Link>
          </div>
          <div className={squareDiv}>
            <Link to="/get-chats">
              <Square title="Get Chats" />
            </Link>
          </div>
          <div className={squareDiv}>
            <Link to="/config">
              <Square title="Config" />
            </Link>
          </div>
          <div className={squareDiv}>
            <Link to="/dashboard">
              <Square title="Your Dashboard" />
            </Link>
          </div>
          <div className={squareDiv}>
            <Link to="/admin/onboarding">
              <Square title="Onboarding creator" />
            </Link>
          </div>
          <div className={squareDiv}>
            <Link to="/admin/videos">
              <Square title="Video How To's" />
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Admin;
