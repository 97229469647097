import React from "react";
import { UserButton, useUser } from "@clerk/clerk-react";
import { Link } from "react-router-dom";
import logo from "../Images/logo.png";
import TimerWrapper from "../Pomodoro/TimerWrapper";
import { useTimer } from "../../context/TimerContext";

const MenuBar: React.FC = () => {
  const { user } = useUser();
  const { isTimerVisible } = useTimer();

  // Determine the route based on whether the user is an admin
  const adminRoute = "/admin";
  const userDashboardRoute = "/dashboard";
  const targetRoute =
    user?.publicMetadata.account === "admin" ? adminRoute : userDashboardRoute;

  return (
    <div className="fixed top-1 left-0 w-full z-10 h-contain mx:h-[150px] flex items-center justify-between px-2 md:px-4">
      {/* Desktop Logo */}
      <div className="hidden lg:block w-32">
        <Link to={targetRoute}>
          <img src={logo} alt="Logo" className="w-full" />
        </Link>
      </div>

      {/* Mobile Logo */}
      <div className="lg:hidden absolute left-1/2 -translate-x-1/2 w-28 h-auto pt-2">
        <Link to={targetRoute}>
          <img src={logo} alt="Logo" className="w-full h-full object-contain" />
        </Link>
      </div>

      {/* Right Section with Timer and User Button */}
      <div className="flex items-center gap-4 ml-auto pt-2 pr-2 lg:pr-0 lg:pt-0 ">
        <div className="hidden lg:block -mt-14 -mr-2">
          {isTimerVisible && <TimerWrapper />}
        </div>
        <div>
          <UserButton />
        </div>
      </div>
    </div>
  );
};

export default MenuBar;
