import React from "react";

const NoticeBoard: React.FC = () => {
  return (
    <div className="fixed right-8 top-24 w-64">
      <div className="bg-[#4b8052] rounded-3xl p-6 shadow-xl">
        <h2 className="text-xl font-bold text-brand-cream mb-4">Notice Board</h2>
        <div className="h-0.5 w-12 bg-brand-cream mb-6 rounded-full"></div>
        
        {/* Placeholder content with matching styles */}
        <div className="space-y-4">
          <div className="bg-brand-green-dark/20 rounded-xl p-4">
            <p className="text-brand-cream/80 text-sm">
              Coming soon...
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NoticeBoard; 