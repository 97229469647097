import React, { createContext, useContext, useState } from "react";
import PomodoroModal from "../components/Pomodoro/PomodoroModal";

interface TimerContextType {
  isTimerVisible: boolean;
  setIsTimerVisible: (visible: boolean) => void;
  showPomodoroModal: boolean;
  openPomodoroModal: () => void;
  closePomodoroModal: () => void;
  handleStartTimer: (workMinutes: number, breakMinutes: number) => void;
  timerConfig: {
    workMinutes: number;
    breakMinutes: number;
  };
}

const TimerContext = createContext<TimerContextType | undefined>(undefined);

export const TimerProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const [isTimerVisible, setIsTimerVisible] = useState(false);
  const [showPomodoroModal, setShowPomodoroModal] = useState(false);
  const [timerConfig, setTimerConfig] = useState({
    workMinutes: 25,
    breakMinutes: 5,
  });

  const openPomodoroModal = () => {
    setShowPomodoroModal(true);
  };

  const closePomodoroModal = () => {
    setShowPomodoroModal(false);
  };

  const handleStartTimer = (workMinutes: number, breakMinutes: number) => {
    setTimerConfig({ workMinutes, breakMinutes });
    setIsTimerVisible(true);
    setShowPomodoroModal(false);
  };

  return (
    <TimerContext.Provider
      value={{
        isTimerVisible,
        setIsTimerVisible,
        showPomodoroModal,
        openPomodoroModal,
        closePomodoroModal,
        handleStartTimer,
        timerConfig,
      }}
    >
      {children}
      {showPomodoroModal && (
        <PomodoroModal
          isOpen={showPomodoroModal}
          onClose={closePomodoroModal}
          onStartTimer={handleStartTimer}
        />
      )}
    </TimerContext.Provider>
  );
};

export const useTimer = () => {
  const context = useContext(TimerContext);
  if (context === undefined) {
    throw new Error("useTimer must be used within a TimerProvider");
  }
  return context;
};

export default TimerContext;
