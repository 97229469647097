import React, { useRef } from "react";
import {
  EmbeddedCheckout,
  EmbeddedCheckoutProvider,
} from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { StripeService } from "../../../services/stripe";
import { useQuery } from "@tanstack/react-query";
import { useNavigate } from "react-router-dom";

const STRIPE_KEY = process.env.REACT_APP_STRIPE_PUBLIC_KEY;

const stripePromise = STRIPE_KEY
  ? loadStripe(STRIPE_KEY).catch((err) => {
      console.error("Failed to load Stripe:", err);
      return null;
    })
  : null;

const OnboardingSteps = () => (
  <div className="absolute top-16 left-0 right-0 flex justify-center">
    <div className="flex items-center space-x-2 sm:space-x-4 scale-75 sm:scale-100 origin-center">
      <div className="flex items-center">
        <div className="w-6 h-6 rounded-full bg-brand-cream text-brand-green flex items-center justify-center text-sm font-medium">
          1
        </div>
        <span className="ml-2 text-brand-cream text-sm">Account</span>
      </div>
      <div className="w-6 sm:w-8 h-[2px] bg-brand-cream opacity-50 mt-[1px]" />
      <div className="flex items-center">
        <div className="w-6 h-6 rounded-full bg-brand-cream text-brand-green flex items-center justify-center text-sm font-medium">
          2
        </div>
        <span className="ml-2 text-brand-cream text-sm font-medium">
          Subscription
        </span>
      </div>
      <div className="w-6 sm:w-8 h-[2px] bg-brand-cream opacity-70 mt-[1px]" />
      <div className="flex items-center opacity-70">
        <div className="w-6 h-6 rounded-full border-2 border-brand-cream text-brand-cream flex items-center justify-center text-sm">
          3
        </div>
        <span className="ml-2 text-brand-cream text-sm">Interview</span>
      </div>
      <div className="w-6 sm:w-8 h-[2px] bg-brand-cream opacity-70 mt-[1px]" />
      <div className="flex items-center opacity-70">
        <div className="w-6 h-6 rounded-full border-2 border-brand-cream text-brand-cream flex items-center justify-center text-sm">
          4
        </div>
        <span className="ml-2 text-brand-cream text-sm">Start</span>
      </div>
    </div>
  </div>
);

const Subscription: React.FC = () => {
  const navigate = useNavigate();
  const checkoutContainerRef = useRef<HTMLDivElement>(null);
  console.log("Rendering Subscription");

  const { data: userSubscription, isLoading } = useQuery({
    queryKey: ["user-subscription-key"],
    queryFn: () => {
      console.log("Fetching user subscription");
      return StripeService.createCheckout();
    },
  });

  const handleComplete = () => {
    console.log("Checkout completed, navigating to onboarding");
    navigate("/onboarding");
  };

  const renderCheckout = () => {
    if (!userSubscription?.clientSecret) return;
    return (
      <EmbeddedCheckoutProvider
        stripe={stripePromise}
        options={{
          clientSecret: userSubscription?.clientSecret,
          onComplete: handleComplete,
        }}
      >
        <EmbeddedCheckout className="min-h-[700px]" />
      </EmbeddedCheckoutProvider>
    );
  };

  return (
    <div className="text-center bg-new-green min-h-screen flex flex-col justify-center items-center text-brand-orange relative">
      <OnboardingSteps />

      {isLoading && (
        <div className="flex flex-col items-center justify-center mt-16">
          <h2 className="text-brand-cream text-3xl md:text-5xl mb-6">
            Loading payment portal...
          </h2>
          <div className="w-12 h-12 border-4 border-brand-cream border-t-transparent rounded-full animate-spin"></div>
        </div>
      )}
      <div className="flex flex-col items-center justify-center mt-24 max-w-md px-4">
        <div className="flex flex-col sm:flex-row gap-4">
          <button
            className="px-6 py-3 rounded-lg bg-brand-cream/30 text-brand-cream font-medium hover:bg-brand-cream/40"
            onClick={() => navigate("/dashboard")}
          >
            Go to Dashboard
          </button>
        </div>
      </div>
      <div
        ref={checkoutContainerRef}
        className="w-full max-w-3xl px-4 mt-24 mb-12 overflow-hidden"
      >
        {renderCheckout()}
      </div>

      <div className="mt-8 space-y-2 text-brand-cream">
        <p className="text-[16px]">
          <a href="https://app.sloane.biz/" className="hover:underline">
            Member login
          </a>
        </p>
        <p className="text-sm opacity-80">
          <a href="/" className="hover:underline">
            Trouble logging in?
          </a>
        </p>
      </div>
    </div>
  );
};

export default Subscription;
