import axios from "axios";
import { Clerk } from "@clerk/clerk-js";

const clerk = new Clerk(process.env.REACT_APP_CLERK_PUBLISHABLE_KEY || "");
(async () => {
  await clerk.load();
})();

const fetcher = axios.create({
  baseURL: process.env.REACT_APP_API_BASE_URL || "http://localhost:3000",
  headers: {
    "Content-Type": "application/json",
  },
});

fetcher.interceptors.request.use(async (config) => {
  try {
    const token = await clerk.session?.getToken();

    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }

    return config;
  } catch (error) {
    return Promise.reject(error);
  }
});

export default fetcher;
