import React, { useState, useEffect } from "react";
import { motion, AnimatePresence } from "framer-motion";
import { useChat } from "../../../context/ChatContext";
import { useFolder } from "../../../context/FolderContext";
import { useNavigate, useParams } from "react-router-dom";
import { useAuth } from "@clerk/clerk-react";
import { service } from "../../../services";
import CloseIcon from "@mui/icons-material/Close";
import FolderIcon from "@mui/icons-material/Folder";
import ChatBubbleOutlineIcon from "@mui/icons-material/ChatBubbleOutline";
import EditIcon from "@mui/icons-material/Edit";
import AddIcon from "@mui/icons-material/Add";
import CreateNewFolderIcon from "@mui/icons-material/CreateNewFolder";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import DragIndicatorIcon from "@mui/icons-material/DragIndicator";
import toast from "react-hot-toast";

interface FullPageMenuProps {
  isOpen: boolean;
  onClose: () => void;
  hubTitle: string;
}

// Define types for chat and folder objects
interface ChatType {
  _id: string;
  title?: string;
  folderId?: string;
}

interface FolderType {
  _id: string;
  title: string;
  chats?: string[];
}

const FullPageMenu: React.FC<FullPageMenuProps> = ({
  isOpen,
  onClose,
  hubTitle,
}) => {
  const {
    selectedChat,
    setSelectedChat,
    allChats,
    getAllChats,
    selectChat,
    moveChat,
  } = useChat();
  const { allFolders, folderChats, getAllFolders } = useFolder();
  const { assistantId } = useParams();
  const navigate = useNavigate();
  const { getToken } = useAuth();
  const [editingChatId, setEditingChatId] = useState<string | null>(null);
  const [editingFolderId, setEditingFolderId] = useState<string | null>(null);
  const [newChatTitle, setNewChatTitle] = useState("");
  const [newFolderName, setNewFolderName] = useState("");
  const [localChats, setLocalChats] = useState<ChatType[]>([]);
  const [localFolders, setLocalFolders] = useState<FolderType[]>([]);
  const [expandedFolders, setExpandedFolders] = useState<
    Record<string, boolean>
  >({});
  const [folderContents, setFolderContents] = useState<
    Record<string, ChatType[]>
  >({});
  const [loadingFolders, setLoadingFolders] = useState<Record<string, boolean>>(
    {}
  );
  const [draggedChat, setDraggedChat] = useState<string | null>(null);
  const [dropTargetFolder, setDropTargetFolder] = useState<string | null>(null);

  // Safely combine chats and ensure we're working with arrays
  useEffect(() => {
    // Ensure allChats and folderChats are arrays before combining
    const chatArray = Array.isArray(allChats) ? allChats : [];

    // Filter out chats that belong to folders, as they'll be shown under their folders
    const standaloneChats = chatArray.filter((chat) => !chat.folderId);
    setLocalChats(standaloneChats);
  }, [allChats, folderChats]);

  // Safely handle folders
  useEffect(() => {
    setLocalFolders(Array.isArray(allFolders) ? allFolders : []);
  }, [allFolders]);

  const handleChatClick = (chatId: string) => {
    // First select the chat to load its data
    selectChat(chatId);
    // Then navigate to the chat URL
    navigate(`/assistant/${assistantId}?chat=${chatId}`);
    // Close the menu
    onClose();
  };

  const handleEditChat = (chatId: string, currentTitle: string) => {
    setEditingChatId(chatId);
    setNewChatTitle(currentTitle || "New Chat");
  };

  const handleSaveTitle = async (chatId: string) => {
    if (newChatTitle.trim()) {
      const lengthOfWords = newChatTitle.split(" ").filter(Boolean).length;
      if (lengthOfWords < 5) {
        try {
          await service.chatService.updateChatName(
            await getToken(),
            newChatTitle,
            chatId
          );
          // Refresh data
          if (getAllChats) {
            getAllChats();
          }
          setEditingChatId(null);
        } catch (error) {
          console.error("Error updating chat title:", error);
          toast.error("Failed to update chat title", {
            duration: 4000,
            position: "top-center",
            style: {
              background: "#FDF3E3",
              color: "#003b1f",
              padding: "12px 16px",
              fontSize: "14px",
            },
          });
        }
      } else {
        toast.error("Please enter a title with less than five words", {
          duration: 4000,
          position: "top-center",
          style: {
            background: "#FDF3E3",
            color: "#003b1f",
            padding: "12px 16px",
            fontSize: "14px",
          },
        });
      }
    }
  };

  const handleDeleteChat = async (chatId: string) => {
    const isConfirmed = window.confirm(
      "Deleting this chat is permanent and it can never be recovered. Click OK to delete or Cancel if you do not want to delete it."
    );

    if (isConfirmed) {
      try {
        await service.chatService.deleteChat(await getToken(), chatId);

        // Refresh data
        if (getAllChats) {
          getAllChats();
        }

        // If the deleted chat was selected, clear the selection
        if (selectedChat?._id === chatId) {
          setSelectedChat(undefined);
          navigate(`/assistant/${assistantId}`);
        }
      } catch (error) {
        console.error("Error deleting chat:", error);
        toast.error("Failed to delete chat", {
          duration: 4000,
          position: "top-center",
          style: {
            background: "#FDF3E3",
            color: "#003b1f",
            padding: "12px 16px",
            fontSize: "14px",
          },
        });
      }
    }
  };

  // Folder edit functionality
  const handleEditFolder = (folderId: string, currentTitle: string) => {
    setEditingFolderId(folderId);
    setNewFolderName(currentTitle || "New Folder");
  };

  const handleSaveFolderName = async (folderId: string) => {
    if (newFolderName.trim()) {
      const lengthOfWords = newFolderName.split(" ").filter(Boolean).length;
      if (lengthOfWords < 5) {
        try {
          await service.chatService.updateFolderName(
            await getToken(),
            newFolderName,
            folderId
          );
          // Refresh data
          if (getAllFolders) {
            getAllFolders();
          }
          setEditingFolderId(null);
        } catch (error) {
          console.error("Error updating folder name:", error);
          toast.error("Failed to update folder name", {
            duration: 4000,
            position: "top-center",
            style: {
              background: "#FDF3E3",
              color: "#003b1f",
              padding: "12px 16px",
              fontSize: "14px",
            },
          });
        }
      } else {
        toast.error("Please enter a name with less than five words", {
          duration: 4000,
          position: "top-center",
          style: {
            background: "#FDF3E3",
            color: "#003b1f",
            padding: "12px 16px",
            fontSize: "14px",
          },
        });
      }
    }
  };

  // Folder delete functionality
  const handleDeleteFolder = async (folderId: string) => {
    const isConfirmed = window.confirm(
      "Deleting this folder is permanent and it can never be recovered. This will delete all chats contained in the folder. Click OK to delete or Cancel if you do not want to delete it."
    );

    if (isConfirmed) {
      try {
        await service.chatService.deleteFolder(await getToken(), folderId);

        // Refresh data
        if (getAllFolders) {
          getAllFolders();
        }
        if (getAllChats) {
          getAllChats();
        }

        // Remove from expanded folders
        setExpandedFolders((prev) => {
          const newState = { ...prev };
          delete newState[folderId];
          return newState;
        });

        // Remove from folder contents
        setFolderContents((prev) => {
          const newState = { ...prev };
          delete newState[folderId];
          return newState;
        });

        toast.success("Folder deleted successfully", {
          duration: 4000,
          position: "top-center",
          style: {
            background: "#FDF3E3",
            color: "#003b1f",
            padding: "12px 16px",
            fontSize: "14px",
          },
        });
      } catch (error) {
        console.error("Error deleting folder:", error);
        toast.error("Failed to delete folder", {
          duration: 4000,
          position: "top-center",
          style: {
            background: "#FDF3E3",
            color: "#003b1f",
            padding: "12px 16px",
            fontSize: "14px",
          },
        });
      }
    }
  };

  const handleCreateFolder = async () => {
    if (!assistantId) {
      toast.error("No assistant selected", {
        duration: 4000,
        position: "top-center",
        style: {
          background: "#FDF3E3",
          color: "#003b1f",
          padding: "12px 16px",
          fontSize: "14px",
        },
      });
      return;
    }

    try {
      // Call the API directly instead of using the context function
      const token = await getToken();
      const response = await fetch(
        `${process.env.REACT_APP_API_BASE_URL}/api/chats/create-folder`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify({ assistantId }),
        }
      );

      if (!response.ok) {
        throw new Error("Failed to create folder");
      }

      const newFolder = await response.json();

      // Refresh folders
      if (getAllFolders) {
        getAllFolders();
      }

      // Expand the new folder
      setExpandedFolders((prev) => ({
        ...prev,
        [newFolder._id]: true,
      }));

      // No need to close the menu or navigate away
      toast.success("New folder created", {
        duration: 4000,
        position: "top-center",
        style: {
          background: "#FDF3E3",
          color: "#003b1f",
          padding: "12px 16px",
          fontSize: "14px",
        },
      });
    } catch (error) {
      console.error("Error creating folder:", error);
      toast.error("Failed to create folder", {
        duration: 4000,
        position: "top-center",
        style: {
          background: "#FDF3E3",
          color: "#003b1f",
          padding: "12px 16px",
          fontSize: "14px",
        },
      });
    }
  };

  // Toggle folder expansion
  const toggleFolderExpansion = async (folderId: string) => {
    // Toggle the expanded state
    setExpandedFolders((prev) => ({
      ...prev,
      [folderId]: !prev[folderId],
    }));

    // If expanding and we don't have the folder contents yet, fetch them
    if (!expandedFolders[folderId] && !folderContents[folderId]) {
      await fetchFolderChats(folderId);
    }
  };

  // Fetch chats for a folder
  const fetchFolderChats = async (folderId: string) => {
    try {
      setLoadingFolders((prev) => ({
        ...prev,
        [folderId]: true,
      }));

      const token = await getToken();
      const chats = await service.chatService.getChatsByFolder(token, folderId);

      setFolderContents((prev) => ({
        ...prev,
        [folderId]: Array.isArray(chats) ? chats : [],
      }));
    } catch (error) {
      console.error("Error fetching folder chats:", error);
      toast.error("Failed to load folder contents", {
        duration: 4000,
        position: "top-center",
        style: {
          background: "#FDF3E3",
          color: "#003b1f",
          padding: "12px 16px",
          fontSize: "14px",
        },
      });
    } finally {
      setLoadingFolders((prev) => ({
        ...prev,
        [folderId]: false,
      }));
    }
  };

  // Create a new chat in a folder
  const handleCreateChatInFolder = async (folderId: string) => {
    try {
      const token = await getToken();

      // Generate a unique title for the new chat
      let newChatTitle = "New Chat";

      // Check if there are existing chats in the folder with similar titles
      if (folderContents[folderId] && folderContents[folderId].length > 0) {
        const existingChats = folderContents[folderId];
        const newChatTitles = existingChats
          .filter((chat) => chat.title && chat.title.startsWith("New Chat"))
          .map((chat) => chat.title);

        if (newChatTitles.length > 0) {
          // Extract numbers from existing titles
          const numbers = newChatTitles.map((title) => {
            const match = title?.match(/New Chat\s*(\d+)?$/);
            return match && match[1] ? parseInt(match[1], 10) : 0;
          });

          // Find the highest number
          const highestNumber = Math.max(
            0,
            ...numbers.filter((n) => !isNaN(n))
          );

          // Increment for the new chat
          newChatTitle =
            highestNumber > 0 ? `New Chat ${highestNumber + 1}` : "New Chat 1";
        }
      }

      // Create the chat with the unique title
      const newChat = await service.chatService.createChat(
        token,
        assistantId,
        newChatTitle
      );

      // Move the chat to the folder
      if (newChat && newChat._id) {
        await service.chatService.moveChats(token, folderId, newChat._id);
      }

      // Refresh the folder contents
      await fetchFolderChats(folderId);

      // Select the new chat and navigate to it
      selectChat(newChat._id);
      navigate(`/assistant/${assistantId}?chat=${newChat._id}`);
      onClose();
    } catch (error) {
      console.error("Error creating chat in folder:", error);
      toast.error("Failed to create new chat", {
        duration: 4000,
        position: "top-center",
        style: {
          background: "#FDF3E3",
          color: "#003b1f",
          padding: "12px 16px",
          fontSize: "14px",
        },
      });
    }
  };

  // Drag and drop functionality
  const handleDragStart = (chatId: string) => {
    setDraggedChat(chatId);
  };

  const handleDragEnd = async () => {
    if (draggedChat && dropTargetFolder) {
      try {
        await moveChat(draggedChat, dropTargetFolder);

        // Refresh data
        if (getAllChats) {
          getAllChats();
        }

        // Refresh folder contents if the folder is expanded
        if (expandedFolders[dropTargetFolder]) {
          await fetchFolderChats(dropTargetFolder);
        }

        toast.success("Chat moved to folder", {
          duration: 4000,
          position: "top-center",
          style: {
            background: "#FDF3E3",
            color: "#003b1f",
            padding: "12px 16px",
            fontSize: "14px",
          },
        });
      } catch (error) {
        console.error("Error moving chat:", error);
        toast.error("Failed to move chat", {
          duration: 4000,
          position: "top-center",
          style: {
            background: "#FDF3E3",
            color: "#003b1f",
            padding: "12px 16px",
            fontSize: "14px",
          },
        });
      }
    }

    // Reset drag state
    setDraggedChat(null);
    setDropTargetFolder(null);
  };

  const handleDragOver = (e: React.DragEvent, folderId: string) => {
    e.preventDefault();
    if (draggedChat) {
      setDropTargetFolder(folderId);
    }
  };

  const handleDragLeave = () => {
    setDropTargetFolder(null);
  };

  // Helper function to truncate long titles
  const truncatedString = (text: string) => {
    if (!text) return "New Item";
    if (text.length <= 15) {
      return text;
    } else {
      return text.substring(0, 20) + "...";
    }
  };

  // Refresh data when menu opens
  useEffect(() => {
    if (isOpen) {
      if (getAllChats) {
        getAllChats();
      }
      if (getAllFolders) {
        getAllFolders();
      }
    }
  }, [isOpen, getAllChats, getAllFolders]);

  if (!isOpen) return null;

  return (
    <motion.div
      className="fixed inset-0 bg-brand-green z-50 flex flex-col"
      initial={{ opacity: 0, y: "100%" }}
      animate={{ opacity: 1, y: 0 }}
      exit={{ opacity: 0, y: "100%" }}
      transition={{ duration: 0.3 }}
    >
      <div className="flex items-center justify-between p-4 border-b border-brand-green-dark/20">
        <h1 className="text-xl font-bold text-brand-cream">{hubTitle}</h1>
        <div className="flex items-center">
          <button
            onClick={onClose}
            className="p-2 rounded-full hover:bg-brand-green-dark/10"
          >
            <CloseIcon className="text-brand-cream hover:text-brand-green" />
          </button>
        </div>
      </div>

      <div className="flex-1 overflow-y-auto p-4">
        {/* New Folder Button */}
        {assistantId && (
          <div className="mb-4">
            <button
              onClick={handleCreateFolder}
              className="flex items-center w-full p-3 rounded-full bg-new-tan border-2 border-brand-green text-brand-green-dark hover:scale-105 transform transition-transform duration-300 ease-in-out"
            >
              <CreateNewFolderIcon className="text-brand-green-dark mr-3" />
              <span className="text-brand-green-dark font-medium">
                Create New Folder
              </span>
            </button>
          </div>
        )}

        {/* Folders Section */}
        <div className="mb-6">
          <h2 className="text-lg font-semibold text-brand-cream mb-2">
            Folders
          </h2>
          {localFolders.length > 0 ? (
            <ul className="space-y-2">
              {localFolders.map((folder: FolderType) => (
                <li
                  key={folder._id}
                  className={`border-b border-brand-green-dark/10 pb-2 ${
                    dropTargetFolder === folder._id
                      ? "bg-brand-cream bg-opacity-50 border-2 border-dashed border-brand-green rounded-[100px] mb-4"
                      : ""
                  }`}
                  onDragOver={(e) => handleDragOver(e, folder._id)}
                  onDragLeave={handleDragLeave}
                  onDrop={handleDragEnd}
                >
                  {editingFolderId === folder._id ? (
                    <div className="flex items-center justify-between py-2 px-4 mb-4 rounded-[100px] border-2 border-brand-cream text-brand-cream h-12">
                      <input
                        type="text"
                        value={newFolderName}
                        onChange={(e) => setNewFolderName(e.target.value)}
                        className="bg-transparent border-none outline-none w-full h-full px-4 py-2 text-brand-cream"
                        style={{ height: "90%", fontSize: "14px" }}
                        autoFocus
                        onBlur={() => handleSaveFolderName(folder._id)}
                        onKeyDown={(e) =>
                          e.key === "Enter" && handleSaveFolderName(folder._id)
                        }
                      />
                    </div>
                  ) : (
                    <div className="flex flex-col">
                      <div className="flex items-center justify-between py-2 px-4 mb-4 rounded-[100px] bg-brand-cream border-brand-cream border text-[14px] font-thin text-brand-green  hover:text-brand-green-dark hover:bg-brand-cream/80 w-full hover:cursor-pointer transform transition-transform duration-300 ease-in-out hover:scale-105">
                        <div
                          className="flex items-center flex-1"
                          onClick={() => toggleFolderExpansion(folder._id)}
                        >
                          <FolderIcon
                            className={`${
                              expandedFolders[folder._id]
                                ? "text-brand-green"
                                : "text-brand-green group-hover:text-brand-green"
                            }`}
                          />
                          <span className="flex-1 ml-4">
                            {truncatedString(folder.title)}
                          </span>
                          {expandedFolders[folder._id] ? (
                            <ExpandLessIcon
                              className={`${
                                expandedFolders[folder._id]
                                  ? "text-brand-green"
                                  : "text-brand-green group-hover:text-brand-green"
                              }`}
                            />
                          ) : (
                            <ExpandMoreIcon
                              className={`${
                                expandedFolders[folder._id]
                                  ? "text-brand-green"
                                  : "text-brand-green group-hover:text-brand-green"
                              }`}
                            />
                          )}
                        </div>
                        <div className="flex">
                          <button
                            onClick={(e) => {
                              e.stopPropagation();
                              handleCreateChatInFolder(folder._id);
                            }}
                            className="flex items-center justify-center ml-2"
                          >
                            <AddIcon
                              className="text-brand-green-dark"
                              fontSize="small"
                            />
                          </button>
                          <button
                            onClick={(e) => {
                              e.stopPropagation();
                              handleEditFolder(folder._id, folder.title);
                            }}
                            className="flex items-center justify-center ml-1"
                          >
                            <EditIcon
                              className="text-brand-green-dark"
                              fontSize="small"
                            />
                          </button>
                          <button
                            onClick={(e) => {
                              e.stopPropagation();
                              handleDeleteFolder(folder._id);
                            }}
                            className="flex items-center justify-center ml-1"
                          >
                            <CloseIcon
                              className="text-brand-green-dark"
                              fontSize="small"
                            />
                          </button>
                        </div>
                      </div>

                      {/* Folder Contents */}
                      <AnimatePresence>
                        {expandedFolders[folder._id] && (
                          <motion.div
                            initial={{ height: 0, opacity: 0 }}
                            animate={{ height: "auto", opacity: 1 }}
                            exit={{ height: 0, opacity: 0 }}
                            transition={{ duration: 0.3 }}
                            className="overflow-hidden"
                          >
                            <div className="w-3/4 h-full border-l pl-2 border-brand-cream/50 ml-4">
                              {loadingFolders[folder._id] ? (
                                <div className="p-3 text-brand-green-dark/60">
                                  Loading chats...
                                </div>
                              ) : folderContents[folder._id]?.length > 0 ? (
                                <ul className="space-y-2 mt-1">
                                  {folderContents[folder._id].map(
                                    (chat: ChatType) => (
                                      <li key={chat._id}>
                                        {editingChatId === chat._id ? (
                                          <div className="flex items-center justify-between py-2 px-4 mb-4 rounded-[100px] border-2 border-brand-cream text-brand-cream h-12">
                                            <input
                                              type="text"
                                              value={newChatTitle}
                                              onChange={(e) =>
                                                setNewChatTitle(e.target.value)
                                              }
                                              className="bg-transparent border-none outline-none w-full h-full px-4 py-2 text-brand-cream"
                                              style={{
                                                height: "90%",
                                                fontSize: "14px",
                                              }}
                                              autoFocus
                                              onBlur={() =>
                                                handleSaveTitle(chat._id)
                                              }
                                              onKeyDown={(e) =>
                                                e.key === "Enter" &&
                                                handleSaveTitle(chat._id)
                                              }
                                            />
                                          </div>
                                        ) : (
                                          <div
                                            className={`flex items-center justify-between py-2 px-4 mb-4 rounded-[100px] text-[14px] font-thin text-brand-cream hover:border-brand-logo hover:text-brand-green-dark active:bg-brand-logo active:text-brand-green-dark w-full hover:cursor-pointer transform transition-transform duration-300 ease-in-out hover:scale-105 hover:bg-brand-logo border-brand-cream border group ${
                                              selectedChat?._id === chat._id
                                                ? "border-brand-green border-[1px] !text-brand-green-dark bg-brand-logo"
                                                : ""
                                            }`}
                                          >
                                            <div
                                              className="flex items-center flex-1"
                                              onClick={() =>
                                                handleChatClick(chat._id)
                                              }
                                            >
                                              <DragIndicatorIcon
                                                className={`${
                                                  selectedChat?._id === chat._id
                                                    ? "text-brand-green mr-1"
                                                    : "text-brand-cream group-hover:text-brand-green mr-1"
                                                }`}
                                              />
                                              <ChatBubbleOutlineIcon
                                                className={`${
                                                  selectedChat?._id === chat._id
                                                    ? "text-brand-green"
                                                    : "text-brand-cream group-hover:text-brand-green"
                                                }`}
                                              />
                                              <span className="flex-1 ml-4 truncate">
                                                {truncatedString(
                                                  chat.title || "New Chat"
                                                )}
                                              </span>
                                            </div>
                                            <div className="flex">
                                              <button
                                                onClick={(e) => {
                                                  e.stopPropagation();
                                                  handleEditChat(
                                                    chat._id,
                                                    chat.title || "New Chat"
                                                  );
                                                }}
                                                className="flex items-center justify-center ml-1"
                                              >
                                                <EditIcon
                                                  className={`${
                                                    selectedChat?._id ===
                                                    chat._id
                                                      ? "text-brand-green"
                                                      : "text-brand-cream group-hover:text-brand-green"
                                                  }`}
                                                />
                                              </button>
                                              <button
                                                onClick={(e) => {
                                                  e.stopPropagation();
                                                  handleDeleteChat(chat._id);
                                                }}
                                                className="flex items-center justify-center ml-1"
                                              >
                                                <CloseIcon
                                                  className={`${
                                                    selectedChat?._id ===
                                                    chat._id
                                                      ? "text-brand-green"
                                                      : "text-brand-cream group-hover:text-brand-green"
                                                  }`}
                                                />
                                              </button>
                                            </div>
                                          </div>
                                        )}
                                      </li>
                                    )
                                  )}
                                </ul>
                              ) : (
                                <div className="p-3 text-brand-green-dark/60 italic">
                                  No chats in this folder
                                  <button
                                    onClick={() =>
                                      handleCreateChatInFolder(folder._id)
                                    }
                                    className="ml-2 text-brand-green-dark hover:underline"
                                  >
                                    Create one
                                  </button>
                                </div>
                              )}
                            </div>
                          </motion.div>
                        )}
                      </AnimatePresence>
                    </div>
                  )}
                </li>
              ))}
            </ul>
          ) : (
            <p className="text-brand-green-dark/60 italic">No folders yet</p>
          )}
        </div>

        {/* Chats Section */}
        <div>
          <h2 className="text-lg font-semibold text-brand-cream mb-2">Chats</h2>
          {localChats.length > 0 ? (
            <ul className="space-y-2">
              {localChats.map((chat: ChatType) => (
                <li key={chat._id}>
                  {editingChatId === chat._id ? (
                    <div className="flex items-center justify-between py-2 px-4 mb-4 rounded-[100px] border-2 border-brand-cream text-brand-cream h-12">
                      <input
                        type="text"
                        value={newChatTitle}
                        onChange={(e) => setNewChatTitle(e.target.value)}
                        className="bg-transparent border-none outline-none w-full h-full px-4 py-2 text-brand-cream"
                        style={{ height: "90%", fontSize: "14px" }}
                        autoFocus
                        onBlur={() => handleSaveTitle(chat._id)}
                        onKeyDown={(e) =>
                          e.key === "Enter" && handleSaveTitle(chat._id)
                        }
                      />
                    </div>
                  ) : (
                    <div
                      className={`flex items-center justify-between py-2 px-4 mb-4 rounded-[100px] text-[14px] font-thin text-brand-cream hover:border-brand-logo hover:text-brand-green-dark active:bg-brand-logo active:text-brand-green-dark w-full hover:cursor-pointer transform transition-transform duration-300 ease-in-out hover:scale-105 hover:bg-brand-logo border-brand-cream border ${
                        selectedChat?._id === chat._id
                          ? "border-brand-green border-[1px] !text-brand-green-dark bg-brand-logo"
                          : ""
                      }`}
                      draggable={true}
                      onDragStart={() => handleDragStart(chat._id)}
                      onDragEnd={handleDragEnd}
                    >
                      <div
                        className="flex items-center flex-1"
                        onClick={() => handleChatClick(chat._id)}
                      >
                        <DragIndicatorIcon
                          className={`${
                            selectedChat?._id === chat._id
                              ? "text-brand-green mr-1"
                              : "text-brand-cream group-hover:text-brand-green mr-1"
                          }`}
                        />
                        <ChatBubbleOutlineIcon
                          className={`${
                            selectedChat?._id === chat._id
                              ? "text-brand-green"
                              : "text-brand-cream group-hover:text-brand-green"
                          }`}
                        />
                        <span className="flex-1 ml-4 truncate">
                          {truncatedString(chat.title || "New Chat")}
                        </span>
                      </div>
                      <div className="flex">
                        <button
                          onClick={(e) => {
                            e.stopPropagation();
                            handleEditChat(chat._id, chat.title || "New Chat");
                          }}
                          className="flex items-center justify-center ml-1"
                        >
                          <EditIcon
                            className={`${
                              selectedChat?._id === chat._id
                                ? "text-brand-green"
                                : "text-brand-cream group-hover:text-brand-green"
                            }`}
                          />
                        </button>
                        <button
                          onClick={(e) => {
                            e.stopPropagation();
                            handleDeleteChat(chat._id);
                          }}
                          className="flex items-center justify-center ml-1"
                        >
                          <CloseIcon
                            className={`${
                              selectedChat?._id === chat._id
                                ? "text-brand-green"
                                : "text-brand-cream group-hover:text-brand-green"
                            }`}
                          />
                        </button>
                      </div>
                    </div>
                  )}
                </li>
              ))}
            </ul>
          ) : (
            <p className="text-brand-green-dark/60 italic">
              No standalone chats
            </p>
          )}
        </div>
      </div>
    </motion.div>
  );
};

export default FullPageMenu;
