import React, { useState } from "react";
import { FaLink, FaStar } from "react-icons/fa";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";

interface OptInFormData {
  name: string;
  biography: string;
  businessDescription: string;
  businessTypes: string[];
  websiteUrl: string;
  funFact: string;
  linkedinUrl: string;
  twitterUrl: string;
  instagramUrl: string;
  facebookUrl: string;
  pinterestUrl: string;
  openToMessages: boolean;
  country: string;
  state: string;
}

interface OptInFormProps {
  formData: OptInFormData;
  onSubmit: (e: React.FormEvent) => void;
  onChange: (name: string, value: string | boolean | string[]) => void;
  onClose: () => void;
}

const BADGE_COLORS = [
  {
    bg: "bg-brand-yellow",
    text: "text-brand-green-dark/80",
  },
];

const getRandomColors = () => {
  return BADGE_COLORS[Math.floor(Math.random() * BADGE_COLORS.length)];
};

export function OptInForm({
  formData,
  onSubmit,
  onChange,
  onClose,
}: OptInFormProps) {
  const [showSocials, setShowSocials] = useState(false);
  const [showFunFact, setShowFunFact] = useState(false);
  const [newBusinessType, setNewBusinessType] = useState("");

  const handleInputChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const { name, value } = e.target;

    // Handle URL formatting for website and social links
    if (name.toLowerCase().includes("url")) {
      const formattedUrl = formatUrl(value);
      onChange(name, formattedUrl);
    } else {
      onChange(name, value);
    }
  };

  const formatUrl = (url: string): string => {
    if (!url) return "";

    // Remove any existing protocol
    let cleanUrl = url.replace(/^(https?:\/\/)?(www\.)?/, "");

    // Don't add protocol if the field is empty or just being typed
    if (cleanUrl.trim() === "") return "";

    // Add https:// if not present
    return `https://${cleanUrl}`;
  };

  const handleAddBusinessType = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter" && newBusinessType.trim()) {
      e.preventDefault();
      const updatedTypes = [...formData.businessTypes, newBusinessType.trim()];
      onChange("businessTypes", updatedTypes);
      setNewBusinessType("");
    }
  };

  const handleRemoveBusinessType = (typeToRemove: string) => {
    const updatedTypes = formData.businessTypes.filter(
      (type) => type !== typeToRemove
    );
    onChange("businessTypes", updatedTypes);
  };

  return (
    <Modal open={true} onClose={onClose}>
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: 800,
          bgcolor: "#4b8052",
          boxShadow: 24,
          borderRadius: 2,
          maxHeight: "80vh",
          display: "flex",
        }}
      >
        {/* Main Content */}
        <div className="flex-1 p-6 overflow-y-auto [&::-webkit-scrollbar]:w-[3px] [&::-webkit-scrollbar-track]:bg-transparent [&::-webkit-scrollbar-thumb]:bg-brand-cream">
          <h2 className="text-2xl font-bold text-brand-cream mb-2">
            Join Our Community
          </h2>
          <div className="h-1 w-20 bg-brand-cream rounded mb-6"></div>

          <form onSubmit={onSubmit} className="space-y-6">
            <div>
              <label className="block text-lg font-medium text-brand-cream mb-2">
                Preferred Name *
              </label>
              <input
                type="text"
                name="name"
                value={formData.name}
                onChange={handleInputChange}
                className="w-full p-4 rounded-lg bg-brand-green-dark/20 text-brand-cream placeholder-brand-cream/50 border-0 focus:ring-0 [&::-webkit-scrollbar]:w-[3px] [&::-webkit-scrollbar-track]:bg-transparent [&::-webkit-scrollbar-thumb]:bg-brand-cream"
                required
                placeholder="What should we call you?"
              />
            </div>

            <div>
              <label className="block text-lg font-medium text-brand-cream mb-2">
                Location *
              </label>
              <div className="grid grid-cols-2 gap-4 mb-1">
                <input
                  type="text"
                  name="country"
                  value={formData.country}
                  onChange={handleInputChange}
                  className="w-full p-4 rounded-lg bg-brand-green-dark/20 text-brand-cream placeholder-brand-cream/50 border-0 focus:ring-0 [&::-webkit-scrollbar]:w-[3px] [&::-webkit-scrollbar-track]:bg-transparent [&::-webkit-scrollbar-thumb]:bg-brand-cream"
                  required
                  placeholder="Country"
                />
                <input
                  type="text"
                  name="state"
                  value={formData.state}
                  onChange={handleInputChange}
                  className="w-full p-4 rounded-lg bg-brand-green-dark/20 text-brand-cream placeholder-brand-cream/50 border-0 focus:ring-0 [&::-webkit-scrollbar]:w-[3px] [&::-webkit-scrollbar-track]:bg-transparent [&::-webkit-scrollbar-thumb]:bg-brand-cream"
                  required
                  placeholder="State/Region"
                />
              </div>
              <p className="text-sm text-brand-cream/80 italic mt-1">
                Your location helps when other members want to network and
                connect with you locally if you're interested.
              </p>
            </div>

            <div>
              <label className="block text-lg font-medium text-brand-cream mb-2">
                Tell People About Yourself *
              </label>
              <textarea
                name="biography"
                value={formData.biography}
                onChange={handleInputChange}
                className="w-full p-4 rounded-lg bg-brand-green-dark/20 text-brand-cream placeholder-brand-cream/50 border-0 focus:ring-0 min-h-[100px] [&::-webkit-scrollbar]:w-[3px] [&::-webkit-scrollbar-track]:bg-transparent [&::-webkit-scrollbar-thumb]:bg-brand-cream"
                required
                placeholder="Share your story..."
              />
            </div>

            <div>
              <label className="block text-lg font-medium text-brand-cream mb-2">
                Business Description *
              </label>
              <textarea
                name="businessDescription"
                value={formData.businessDescription}
                onChange={handleInputChange}
                className="w-full p-4 rounded-lg bg-brand-green-dark/20 text-brand-cream placeholder-brand-cream/50 border-0 focus:ring-0 min-h-[100px] [&::-webkit-scrollbar]:w-[3px] [&::-webkit-scrollbar-track]:bg-transparent [&::-webkit-scrollbar-thumb]:bg-brand-cream"
                required
                placeholder="Tell us about your business..."
              />
            </div>

            <div>
              <label className="block text-lg font-medium text-brand-cream mb-2">
                Business Types
              </label>
              <div className="space-y-3">
                <div className="flex flex-wrap gap-2 min-h-[2rem]">
                  {formData.businessTypes.map((type) => {
                    const colors = getRandomColors();
                    return (
                      <span
                        key={type}
                        className={`inline-flex items-center px-3 py-1 rounded-full text-sm ${colors.bg} ${colors.text}`}
                      >
                        {type}
                        <button
                          type="button"
                          onClick={() => handleRemoveBusinessType(type)}
                          className="ml-2 hover:opacity-80"
                        >
                          ×
                        </button>
                      </span>
                    );
                  })}
                </div>
                <input
                  type="text"
                  value={newBusinessType}
                  onChange={(e) => setNewBusinessType(e.target.value)}
                  onKeyDown={handleAddBusinessType}
                  className="w-full p-4 rounded-lg bg-brand-green-dark/20 text-brand-cream placeholder-brand-cream/50 border-0 focus:ring-0 [&::-webkit-scrollbar]:w-[3px] [&::-webkit-scrollbar-track]:bg-transparent [&::-webkit-scrollbar-thumb]:bg-brand-cream"
                  placeholder="Type and press Enter to add business type (e.g., 'Digital Marketing', 'E-commerce')"
                />
                <p className="text-sm text-brand-cream/80 italic">
                  Add multiple business types to help others find you for
                  potential collaborations
                </p>
              </div>
            </div>

            <div>
              <label className="block text-lg font-medium text-brand-cream mb-2">
                Website URL
              </label>
              <input
                type="text"
                name="websiteUrl"
                value={formData.websiteUrl.replace(/^https?:\/\//, "")}
                onChange={handleInputChange}
                className="w-full p-4 rounded-lg bg-brand-green-dark/20 text-brand-cream placeholder-brand-cream/50 border-0 focus:ring-0 [&::-webkit-scrollbar]:w-[3px] [&::-webkit-scrollbar-track]:bg-transparent [&::-webkit-scrollbar-thumb]:bg-brand-cream"
                placeholder="www.yourwebsite.com"
              />
            </div>

            {showFunFact && (
              <div>
                <label className="block text-lg font-medium text-brand-cream mb-2">
                  Fun Fact About Me
                </label>
                <input
                  type="text"
                  name="funFact"
                  value={formData.funFact}
                  onChange={handleInputChange}
                  className="w-full p-4 rounded-lg bg-brand-green-dark/20 text-brand-cream placeholder-brand-cream/50 border-0 focus:ring-0 [&::-webkit-scrollbar]:w-[3px] [&::-webkit-scrollbar-track]:bg-transparent [&::-webkit-scrollbar-thumb]:bg-brand-cream"
                  placeholder="Share something interesting about yourself!"
                />
              </div>
            )}

            <div className="space-y-3">
              <label className="flex items-center space-x-2 text-brand-cream cursor-pointer">
                <input
                  type="checkbox"
                  name="openToMessages"
                  checked={formData.openToMessages}
                  onChange={(e) => onChange("openToMessages", e.target.checked)}
                  className="rounded border-brand-cream bg-transparent"
                />
                <span>Open to receiving messages from other users</span>
              </label>
            </div>

            {showSocials && (
              <div className="space-y-4">
                <h3 className="text-lg font-medium text-brand-cream">
                  Social Links
                </h3>
                <div>
                  <label className="block text-sm font-medium text-brand-cream/80 mb-1">
                    LinkedIn
                  </label>
                  <input
                    type="text"
                    name="linkedinUrl"
                    value={formData.linkedinUrl.replace(/^https?:\/\//, "")}
                    onChange={handleInputChange}
                    className="w-full p-4 rounded-lg bg-brand-green-dark/20 text-brand-cream placeholder-brand-cream/50 border-0 focus:ring-0 [&::-webkit-scrollbar]:w-[3px] [&::-webkit-scrollbar-track]:bg-transparent [&::-webkit-scrollbar-thumb]:bg-brand-cream"
                    placeholder="linkedin.com/in/yourprofile"
                  />
                </div>

                <div>
                  <label className="block text-sm font-medium text-brand-cream/80 mb-1">
                    Twitter
                  </label>
                  <input
                    type="text"
                    name="twitterUrl"
                    value={formData.twitterUrl.replace(/^https?:\/\//, "")}
                    onChange={handleInputChange}
                    className="w-full p-4 rounded-lg bg-brand-green-dark/20 text-brand-cream placeholder-brand-cream/50 border-0 focus:ring-0 [&::-webkit-scrollbar]:w-[3px] [&::-webkit-scrollbar-track]:bg-transparent [&::-webkit-scrollbar-thumb]:bg-brand-cream"
                    placeholder="twitter.com/yourhandle"
                  />
                </div>

                <div>
                  <label className="block text-sm font-medium text-brand-cream/80 mb-1">
                    Instagram
                  </label>
                  <input
                    type="text"
                    name="instagramUrl"
                    value={formData.instagramUrl.replace(/^https?:\/\//, "")}
                    onChange={handleInputChange}
                    className="w-full p-4 rounded-lg bg-brand-green-dark/20 text-brand-cream placeholder-brand-cream/50 border-0 focus:ring-0 [&::-webkit-scrollbar]:w-[3px] [&::-webkit-scrollbar-track]:bg-transparent [&::-webkit-scrollbar-thumb]:bg-brand-cream"
                    placeholder="instagram.com/yourprofile"
                  />
                </div>

                <div>
                  <label className="block text-sm font-medium text-brand-cream/80 mb-1">
                    Facebook
                  </label>
                  <input
                    type="text"
                    name="facebookUrl"
                    value={formData.facebookUrl.replace(/^https?:\/\//, "")}
                    onChange={handleInputChange}
                    className="w-full p-4 rounded-lg bg-brand-green-dark/20 text-brand-cream placeholder-brand-cream/50 border-0 focus:ring-0 [&::-webkit-scrollbar]:w-[3px] [&::-webkit-scrollbar-track]:bg-transparent [&::-webkit-scrollbar-thumb]:bg-brand-cream"
                    placeholder="facebook.com/yourprofile"
                  />
                </div>

                <div>
                  <label className="block text-sm font-medium text-brand-cream/80 mb-1">
                    Pinterest
                  </label>
                  <input
                    type="text"
                    name="pinterestUrl"
                    value={formData.pinterestUrl.replace(/^https?:\/\//, "")}
                    onChange={handleInputChange}
                    className="w-full p-4 rounded-lg bg-brand-green-dark/20 text-brand-cream placeholder-brand-cream/50 border-0 focus:ring-0 [&::-webkit-scrollbar]:w-[3px] [&::-webkit-scrollbar-track]:bg-transparent [&::-webkit-scrollbar-thumb]:bg-brand-cream"
                    placeholder="pinterest.com/yourprofile"
                  />
                </div>
              </div>
            )}
          </form>
        </div>

        {/* Sidebar */}
        <div className="w-48 bg-brand-green-dark/20 p-4 flex flex-col gap-3">
          <button
            onClick={() => setShowSocials(!showSocials)}
            className={`flex items-center gap-2 px-4 py-2 rounded-lg transition-colors ${
              showSocials
                ? "bg-brand-green text-brand-cream"
                : "text-brand-cream/80 hover:bg-brand-green/20"
            }`}
          >
            <FaLink />
            Social Links
          </button>

          <button
            onClick={() => setShowFunFact(!showFunFact)}
            className={`flex items-center gap-2 px-4 py-2 rounded-lg transition-colors ${
              showFunFact
                ? "bg-brand-green text-brand-cream"
                : "text-brand-cream/80 hover:bg-brand-green/20"
            }`}
          >
            <FaStar />
            Fun Fact
          </button>

          {/* Footer Buttons */}
          <div className="mt-auto flex flex-col gap-2">
            <button
              onClick={onClose}
              className="w-full px-6 py-2 rounded-lg border border-brand-cream text-brand-cream hover:bg-brand-cream/10 transition-colors"
            >
              Cancel
            </button>
            <button
              onClick={(e) => onSubmit(e as any)}
              className="w-full px-6 py-2 rounded-lg bg-brand-green text-brand-cream hover:bg-brand-green-dark transition-colors"
            >
              Join Community
            </button>
          </div>
        </div>
      </Box>
    </Modal>
  );
}
