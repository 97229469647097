import { TextField } from "@mui/material";
import { useFormik } from "formik";
import { useChat } from "../../../context/ChatContext";
import Icon from "../../Icons/Icon";
import { useTheme } from "./ThemeContext";
import { useRef, useState, useEffect } from "react";
import VoiceToText from "../Onboarding/VoiceToText";
import toast from "react-hot-toast";

interface IProps {
  room: string;
}

const TextInput: React.FC<IProps> = ({ room }) => {
  const { sendChat } = useChat();
  const { theme } = useTheme();
  const textFieldRef = useRef<HTMLTextAreaElement | null>(null);
  const [isListening, setIsListening] = useState(false);
  const recognitionRef = useRef<any>(null);
  const [showHoverText, setShowHoverText] = useState(false);
  let hoverTimeout: NodeJS.Timeout;

  const handleMouseEnter = () => {
    hoverTimeout = setTimeout(() => {
      setShowHoverText(true);
      setTimeout(() => {
        setShowHoverText(false);
      }, 2000);
    }, 1000);
  };

  const handleMouseLeave = () => {
    clearTimeout(hoverTimeout);
    setShowHoverText(false);
  };

  useEffect(() => {
    return () => {
      clearTimeout(hoverTimeout);
    };
  });

  const handleToggleListening = () => {
    if (!("webkitSpeechRecognition" in window)) {
      toast.error(
        "Speech recognition is not supported in this browser. Please try Chrome."
      );
      return;
    }
    setIsListening(!isListening);

    if (!isListening) {
      toast(
        <div className="flex items-center justify-center gap-3 ">
          <div className="bg-brand-green/20 p-2 rounded-full">
            🎤
          </div>
          <span className="text-brand-green-dark font-medium text-sm">
            <span className="font-bold">Mic on</span> - Start speaking and Sloane will hear you. <br></br>Click mic to turn off when you're finished.
          </span>
        </div>,
        {
          duration: 4000,
          position: "top-center",
          style: {
            background: "#FDF3E3",
            padding: "14px",
            color: "#003b1f",
            boxShadow: "0 4px 12px rgba(0, 0, 0, 0.1)",
            border: "1px solid rgba(0, 0, 0, 0.05)",
            width: "auto",
            maxWidth: "500px",
            margin: "0 auto",
          },
        }
      );
    } else {
      toast(
        <div className="flex items-center justify-center gap-3 ">
          <div className="bg-brand-green/20 p-2 rounded-full text-sm">
            🎤
          </div>
          <span className="text-brand-green-dark font-medium">
            <span className="font-bold">Mic off!</span> Thanks for sharing!
          </span>
        </div>,
        {
          duration: 1500,
          position: "top-center",
          style: {
            background: "#FDF3E3",
            padding: "14px",
            color: "#003b1f",
            boxShadow: "0 4px 12px rgba(0, 0, 0, 0.1)",
            border: "1px solid rgba(0, 0, 0, 0.05)",
            width: "auto",
            maxWidth: "500px",
            margin: "0 auto",
          },
        }
      );
    }
  };

  const handleTranscriptChange = (text: string) => {
    formik.setFieldValue("question", formik.values.question + text);
  };

  // Initialize Formik
  const formik = useFormik({
    initialValues: { question: "" },
    onSubmit: (values, { resetForm }) => {
      sendChat(values.question, room);
      formik.setFieldValue("question", "");
      resetForm();
    },
  });

  return (
    <form onSubmit={formik.handleSubmit}>
      <div
        className={`${theme === "dark" ? "bg-brand-cream" : "bg-brand-green"
          } rounded-[30px] px-4 mb-4 flex justify-center h-contain items-center text-brand-logo py-[1px]`}
      >
        <TextField
          id="question"
          name="question"
          onChange={formik.handleChange}
          value={formik.values.question}
          placeholder={formik.values.question === "" ? "Ask a question..." : ""}
          className="bg-brand-blue-light w-full px-2 focus:outline-none text-brand-logo overflow-y-auto max-h-[200px] lg:max-h-[400px] 1xl:max-h-[600px] text-wrap rounded-lg textarea1-scrollbar"
          multiline
          inputRef={(el) => {
            if (el) {
              textFieldRef.current = el;
            }
          }}
          onKeyDown={(e: React.KeyboardEvent<HTMLDivElement>) => {
            if (e.shiftKey && e.key === "Enter") {
              e.preventDefault();
              const textarea = textFieldRef.current;
              if (!textarea) return;

              const cursorPosition = textarea.selectionStart;
              const currentValue = formik.values.question;
              const newValue =
                currentValue.slice(0, cursorPosition) +
                "\n" +
                currentValue.slice(cursorPosition);

              formik.setFieldValue("question", newValue, false);

              requestAnimationFrame(() => {
                if (textarea) {
                  textarea.focus();
                  textarea.setSelectionRange(
                    cursorPosition + 1,
                    cursorPosition + 1
                  );
                }
              });
            } else if (e.key === "Enter") {
              e.preventDefault();
              formik.handleSubmit();
            }
          }}
          sx={{
            backgroundColor: "bg-brand-blue-light",
            color: theme === "dark" ? "#00BF63" : "#FDF3E3",
            ".MuiOutlinedInput-root": {
              "& fieldset": {
                borderColor: "transparent",
              },
              "&:hover fieldset": {
                borderColor: "transparent",
              },
              "&.Mui-focused fieldset": {
                borderColor: "transparent",
              },
            },
            "& .MuiInputBase-input": {
              backgroundColor:
                theme === "dark" ? "bg-brand-green-dark" : "bg-brand-green",
              color: theme === "dark" ? "#075b33" : "#FDF3E3",
            },
            "& .MuiInput-underline:after": {
              borderBottomColor: "transparent",
            },
            "& .MuiInput-underline:before": {
              borderBottom: "none",
            },
            "&:hover .MuiInput-underline:before": {
              borderBottom: "none",
            },
            "& .MuiInput-underline:hover:not(.Mui-disabled):before": {
              borderBottom: "none",
            },
            "& .MuiInputBase-input::placeholder": {
              color: theme === "dark" ? "#075b33" : "#FDF3E3",
              opacity: 0.7,
            },
            borderRadius: "rounded-md",
            border: "none",
            outline: "none",
          }}
        />

        <div className="items-center gap-2 flex">
          <button
            type="button"
            onClick={handleToggleListening}
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
            className="text-2xl p-2 rounded-full transition-transform duration-300 relative group hidden "
          >
            {isListening && (
              <>
                <span className="absolute inset-0 border-2 border-red-500 rounded-full animate-ping opacity-50" />
                <span className="absolute inset-0 border-2 border-red-500 rounded-full animate-ping opacity-75" style={{ animationDelay: '0.5s' }} />
              </>
            )}
            <span className={`hidden lg:block absolute -top-12 -left-24 bg-brand-cream text-brand-green-dark px-3 py-2 rounded-lg text-xs whitespace-pre-line text-center opacity-0 ${showHoverText ? 'opacity-100' : 'opacity-0'} transition-opacity duration-200 pointer-events-none w-48`}>
              Press to use the microphone
            </span>
            <span className={`inline-block transition-transform duration-300 ${isListening ? "text-red-500 rotate-0" : "text-gray-500 -rotate-45"}`}>
              🎤
            </span>
          </button>
          <button type="submit" className="flex items-end justify-start">
            <div className="w-8 h-7">
              <Icon name="submit" />
            </div>
          </button>
        </div>
      </div>
      <VoiceToText
        onTranscriptChange={handleTranscriptChange}
        isListening={isListening}
        setIsListening={() => setIsListening(false)}
        onToggleListening={handleToggleListening}
        recognitionRef={recognitionRef}
      />
    </form>
  );
};

export default TextInput;
