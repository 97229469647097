import React, { useState } from "react";
import {
  FaLinkedin,
  FaTwitter,
  FaInstagram,
  FaFacebook,
  FaPinterest,
  FaGlobe,
  FaPlus,
} from "react-icons/fa";
import { BsChatDots } from "react-icons/bs";
import { IconType } from "react-icons";
import FullBioModal from "./FullBioModal";
import { CommunityMember } from "../../../types/community";

interface CommunityMemberCardProps {
  member: CommunityMember;
}

const CommunityMemberCard: React.FC<CommunityMemberCardProps> = ({
  member,
}) => {
  const [showModal, setShowModal] = useState(false);

  const getSocialIcon = (
    url: string | undefined,
    Icon: IconType,
    label: string
  ) => {
    if (!url) return null;
    return (
      <a
        href={url}
        target="_blank"
        rel="noopener noreferrer"
        className="text-brand-green hover:text-brand-green-dark transition-colors"
        aria-label={label}
      >
        <Icon size={20} />
      </a>
    );
  };

  // Update the badge style to use brand-yellow
  const BADGE_STYLE = {
    bg: "bg-brand-yellow",
    text: "text-brand-green-dark/80",
  };

  return (
    <>
      <div className="bg-brand-cream rounded-2xl p-6 relative">
        {/* Plus Icon Button - Top Right */}
        <div className="absolute top-4 right-4 group">
          <button
            onClick={() => setShowModal(true)}
            className="w-8 h-8 rounded-full bg-brand-green/10 text-brand-green hover:bg-brand-green hover:text-brand-cream transition-colors flex items-center justify-center"
            aria-label="Show more details"
          >
            <FaPlus size={16} />
          </button>
          <div className="absolute left-full ml-2 opacity-0 group-hover:opacity-100 transition-opacity duration-200">
            <div className="relative bg-brand-green-dark text-brand-cream text-xs py-1 px-2 rounded whitespace-nowrap">
              Tell me more
              <div className="absolute top-1/2 -translate-y-1/2 right-full border-[6px] border-transparent border-r-brand-green-dark"></div>
            </div>
          </div>
        </div>

        {/* Two Column Layout */}
        <div className="flex gap-6">
          {/* Left Column - 1/3 width */}
          <div className="w-1/3 flex flex-col items-center justify-between">
            {/* Profile Image */}
            <div className="w-40 h-40 -mt-16 rounded-full border-4 border-brand-cream shadow-lg overflow-hidden bg-brand-green/10 flex items-center justify-center">
              <span className="text-4xl font-bold text-brand-green">IT</span>
            </div>

            {/* Social Links in Row */}
            <div className="flex flex-row flex-wrap justify-center gap-4 mt-4">
              {getSocialIcon(
                member.communityDetails.linkedinUrl,
                FaLinkedin,
                "LinkedIn"
              )}
              {getSocialIcon(
                member.communityDetails.twitterUrl,
                FaTwitter,
                "Twitter"
              )}
              {getSocialIcon(
                member.communityDetails.instagramUrl,
                FaInstagram,
                "Instagram"
              )}
              {getSocialIcon(
                member.communityDetails.facebookUrl,
                FaFacebook,
                "Facebook"
              )}
              {getSocialIcon(
                member.communityDetails.pinterestUrl,
                FaPinterest,
                "Pinterest"
              )}
              {getSocialIcon(
                member.communityDetails.websiteUrl,
                FaGlobe,
                "Website"
              )}
            </div>
          </div>

          {/* Right Column - 2/3 width */}
          <div className="w-2/3">
            {/* Member Details */}
            <div className="flex-1">
              {/* Preferred Name with Message Icon */}
              <div className="flex items-center">
                <h3 className="text-2xl font-semibold text-brand-green leading-none">
                  {member.communityDetails.preferredName || member.name}
                </h3>
                {/* {member.communityDetails.openToMessages && (
                  <div
                    className="flex items-center text-brand-green/70 hover:text-brand-green transition-colors"
                    title="Open to messages"
                  >
                    <BsChatDots className="w-4 h-4" />
                  </div>
                )} */}
              </div>

              {/* Business Name - Added */}
              <p className="text-base text-brand-green-dark ">
                <span className="font-semibold">Business:</span>{" "}
                {member.businessProfile?.businessName ?? "No business name"}
              </p>
              {/* Location */}
              {(member.communityDetails.state ||
                member.communityDetails.country) && (
                <p className="text-sm text-brand-green-dark mb-4">
                  {[
                    member.communityDetails.state,
                    member.communityDetails.country,
                  ]
                    .filter(Boolean)
                    .join(", ")}
                </p>
              )}

              {/* Fun Fact - Added below location */}
              {member.communityDetails.funFact && (
                <p className="text-sm text-brand-green-dark/80 italic">
                  "{member.communityDetails.funFact}"
                </p>
              )}
            </div>
            {/* Business Types - Moved above name */}
            <div className="flex flex-wrap gap-2 mt-3">
              {member.businessProfile?.businessTypes?.map((type) => (
                <span
                  key={type}
                  className={`px-2 py-0.5 rounded-md text-xs ${BADGE_STYLE.bg} ${BADGE_STYLE.text}`}
                >
                  {type ?? "No business type"}
                </span>
              ))}
            </div>
          </div>
        </div>

        {/* Message Button - Bottom Right */}
        {member.communityDetails.openToMessages && (
          <div className="absolute bottom-4 right-4 group">
            <button
              className="w-10 h-10 rounded-full bg-brand-green/10 text-brand-green hover:bg-brand-green hover:text-brand-cream transition-colors flex items-center justify-center"
              aria-label="Send message"
            >
              <BsChatDots size={20} />
            </button>
            <div className="absolute left-full ml-2 opacity-0 group-hover:opacity-100 transition-opacity duration-200">
              <div className="relative bg-brand-green-dark text-brand-cream text-xs py-1 px-2 rounded whitespace-nowrap">
                Open to Messages
                <div className="absolute top-1/2 -translate-y-1/2 right-full border-[6px] border-transparent border-r-brand-green-dark"></div>
              </div>
            </div>
          </div>
        )}
      </div>

      {/* Member Details Modal */}
      <FullBioModal
        open={showModal}
        onClose={() => setShowModal(false)}
        member={member}
      />
    </>
  );
};

export default CommunityMemberCard;
