import React from "react";
import NewsReel from "../../NewsReel/NewsReel";

interface JoinCommunityPromptProps {
  onJoinClick: () => void;
}

const communityPhrases = [
  "Join a community of like-minded entrepreneurs",
  "Connect with other business owners",
  "Share your journey and learn from others",
  "Build meaningful relationships",
  "Grow together with the Sloane community",
  "Network with passionate entrepreneurs",
  "Be part of something bigger",
  "Create valuable connections",
  "Exchange ideas and experiences",
  "Support and be supported",
];

export default function JoinCommunityPrompt({
  onJoinClick,
}: JoinCommunityPromptProps) {
  return (
    <div className="relative min-h-screen">
      {/* Centered Content */}
      <div className="absolute inset-0 flex flex-col items-center justify-center">
        <h1 className="text-4xl md:text-6xl font-bold text-brand-cream text-center mb-12">
          Join The Sloane Community
        </h1>

        {/* Video Container */}
        <div className="w-full max-w-3xl aspect-video bg-brand-green-dark rounded-lg mb-8">
          {/* Video placeholder - replace with actual video component */}
          <div className="w-full h-full flex items-center justify-center text-brand-cream">
            Video Coming Soon
          </div>
        </div>

        <button
          onClick={onJoinClick}
          className="px-8 py-3 bg-brand-cream text-brand-green rounded-lg font-Black hover:bg-brand-cream/90 transition-colors"
        >
          Join The Community
        </button>
      </div>

      {/* News Reel - Fixed at bottom */}
      <div className="fixed bottom-0 left-0 right-0 bg-brand-cream/10 backdrop-blur-sm">
        <NewsReel phrases={communityPhrases} />
      </div>
    </div>
  );
}
