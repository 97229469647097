import React, { useEffect, useState } from "react";
import { useUser } from "@clerk/clerk-react";
import { communityService } from "../../../services/communityService";
import { useAuth } from "@clerk/clerk-react";
import { OptInForm } from "./OptInForm";
import CommunityMemberCard from "./CommunityMemberCard";
import JoinCommunityPrompt from "./JoinCommunityPrompt";
import Sidebar from "../../Sidebar/Sidebar";
import NoticeBoard from "../../NoticeBoard/NoticeBoard";
import { CommunityMember } from "../../../types/community";

interface OptInFormData {
  name: string;
  biography: string;
  businessDescription: string;
  businessType: string;
  websiteUrl: string;
  funFact: string;
  linkedinUrl: string;
  twitterUrl: string;
  instagramUrl: string;
  facebookUrl: string;
  pinterestUrl: string;
  openToMessages: boolean;
  country: string;
  state: string;
  businessTypes: string[];
}

function Community() {
  const { getToken } = useAuth();
  const { user } = useUser();
  const [communityMembers, setCommunityMembers] = useState<CommunityMember[]>(
    []
  );
  const [showOptInForm, setShowOptInForm] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [isOptedIn, setIsOptedIn] = useState(false);
  const [formData, setFormData] = useState<OptInFormData>({
    name: "",
    biography: "",
    businessDescription: "",
    businessType: "",
    websiteUrl: "",
    funFact: "",
    linkedinUrl: "",
    twitterUrl: "",
    instagramUrl: "",
    facebookUrl: "",
    pinterestUrl: "",
    openToMessages: false,
    country: "",
    state: "",
    businessTypes: [],
  });

  useEffect(() => {
    const checkOptInStatus = async () => {
      try {
        const token = await getToken();
        if (!token) return;

        const isOptedIn = await communityService.getCommunityStatus(
          token,
          user?.id || ""
        );
        setIsOptedIn(isOptedIn);
      } catch (error) {
        console.error("Error checking opt-in status:", error);
      }
    };

    if (user) {
      checkOptInStatus();
    }
  }, [getToken, user]);

  useEffect(() => {
    const fetchCommunityMembers = async () => {
      try {
        const token = await getToken();
        if (!token) return;

        const members = await communityService.getCommunityMembers(token);
        setCommunityMembers(members);
      } catch (error) {
        console.error("Error fetching community members:", error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchCommunityMembers();
  }, [getToken, isOptedIn]);

  const handleInputChange = (
    name: string,
    value: string | boolean | string[]
  ) => {
    setFormData((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleOptIn = async (e: React.FormEvent) => {
    e.preventDefault();
    try {
      console.log("Attempting to opt in with form data:", formData);
      const token = await getToken();
      if (!token) {
        console.log("No token found");
        return;
      }

      console.log("Token found, calling optInToCommunity");
      await communityService.optInToCommunity(token, user?.id || "", formData);
      console.log("Opt in successful");
      setIsOptedIn(true);
      setShowOptInForm(false);
    } catch (error) {
      console.error("Error opting in:", error);
    }
  };

  return (
    <div className="flex min-h-screen bg-brand-green">
      {isOptedIn ? (
        <>
          {/* Full Community View */}
          <Sidebar />
          <div className="flex-1 ml-64 mr-72">
            <div className="pt-20 px-4 md:px-8">
              {/* Header Section */}
              <div className="text-center mb-12">
                <h1 className="text-4xl md:text-5xl font-bold text-brand-cream mb-4">
                  The Sloane Community
                </h1>
                <p className="text-xl text-brand-cream/90 italic">
                  "Alone we are capable of amazing things, together we can
                  achieve incredible things"
                </p>
                <div className="h-1 w-32 bg-brand-cream mx-auto mt-6 rounded-full"></div>
              </div>

              {/* Community Members */}
              <div className="mt-8">
                {isLoading ? (
                  <div className="text-center text-brand-cream">Loading...</div>
                ) : (
                  <div className="grid grid-cols-1 md:grid-cols-2 gap-6 mt-16">
                    {communityMembers.map((member, index) => (
                      <CommunityMemberCard key={index} member={member} />
                    ))}
                  </div>
                )}
              </div>
            </div>
          </div>
          <NoticeBoard />
        </>
      ) : (
        // Non-member view - just show the opt-in form or prompt
        <div className="w-full">
          {showOptInForm ? (
            <OptInForm
              formData={formData}
              onSubmit={handleOptIn}
              onChange={handleInputChange}
              onClose={() => setShowOptInForm(false)}
            />
          ) : (
            <JoinCommunityPrompt onJoinClick={() => setShowOptInForm(true)} />
          )}
        </div>
      )}
    </div>
  );
}

export default Community;
