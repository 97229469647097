import React, { useState, useEffect, useCallback } from "react";
import { FaPause, FaPlay, FaStop, FaCoffee } from "react-icons/fa";
import { toast } from "react-hot-toast";

interface TimerProps {
  workMinutes: number;
  breakMinutes: number;
  onStop: () => void;
  onComplete: () => void;
}

const Timer: React.FC<TimerProps> = ({
  workMinutes,
  breakMinutes,
  onStop,
  onComplete,
}) => {
  const [minutes, setMinutes] = useState(workMinutes);
  const [seconds, setSeconds] = useState(0);
  const [isPaused, setIsPaused] = useState(false);
  const [isBreak, setIsBreak] = useState(false);
  const [waitingForUserInput, setWaitingForUserInput] = useState(false);
  const [isShrunken, setIsShrunken] = useState(false);
  const [isHovered, setIsHovered] = useState(false);
  const [isShaking, setIsShaking] = useState(false);

  const startShakeAnimation = () => {
    setIsShaking(true);
    setTimeout(() => setIsShaking(false), 1000); // Reset after 1 second
  };

  const getToastStyle = () => ({
    background: "#FDF3E3",
    padding: "16px",
    color: "#003b1f",
    boxShadow: "0 4px 12px rgba(0, 0, 0, 0.1)",
    border: "1px solid rgba(0, 0, 0, 0.05)",
    width: "auto",
    maxWidth: "500px",
    margin: "0 auto",
  });

  const handlePause = () => {
    setIsPaused(!isPaused);
    toast(
      <div className="flex items-center justify-center gap-3 min-w-[300px]">
        <div className="bg-brand-green/20 p-2 rounded-full">
          {!isPaused ? (
            <FaPause className="text-brand-green text-lg" />
          ) : (
            <FaPlay className="text-brand-green text-lg" />
          )}
        </div>
        <span className="text-brand-green-dark font-medium">
          Timer {!isPaused ? "paused" : "resumed"}
        </span>
      </div>,
      {
        duration: 2000,
        position: "top-center",
        style: getToastStyle(),
      }
    );
  };

  const handleStop = useCallback(() => {
    setIsBreak(false);
    setWaitingForUserInput(false);
    onStop();
    toast(
      <div className="flex items-center justify-center gap-3 min-w-[300px]">
        <div className="bg-brand-green/20 p-2 rounded-full">⏹️</div>
        <span className="text-brand-green-dark font-medium">
          Timer stopped. Ready for your next focused session!
        </span>
      </div>,
      {
        duration: 2000,
        position: "top-center",
        style: {
          background: "#FDF3E3",
          color: "#003b1f",
          padding: "14px",
          boxShadow: "0 4px 12px rgba(0, 0, 0, 0.1)",
          border: "1px solid rgba(0, 0, 0, 0.05)",
          width: "auto",
          maxWidth: "500px",
          margin: "0 auto",
        },
      }
    );
  }, [onStop]);

  const showRepeatToast = useCallback(() => {
    toast(
      (t) => (
        <div className="flex flex-col gap-4 min-w-[300px]">
          <div className="flex items-center justify-center gap-3">
            <div className="bg-brand-green/20 p-2 rounded-full">
              <FaCoffee className="text-brand-green text-lg" />
            </div>
            <span className="text-brand-green-dark font-medium">
              {isBreak ? "Break" : "Work"} session completed!
            </span>
          </div>
          <div className="flex gap-2">
            <button
              onClick={() => {
                toast.dismiss(t.id);
                onStop();
              }}
              className="bg-red-500 text-brand-cream px-4 py-2 rounded-xl hover:bg-red-600 transition-colors w-full"
            >
              Stop
            </button>
            <button
              onClick={() => {
                toast.dismiss(t.id);
                onComplete();
              }}
              className="bg-brand-green text-brand-cream px-4 py-2 rounded-xl hover:bg-brand-green-dark transition-colors w-full"
            >
              Continue
            </button>
          </div>
        </div>
      ),
      {
        duration: Infinity,
        position: "top-center",
        style: {
          background: "var(--brand-cream)",
          color: "var(--brand-green-dark)",
        },
      }
    );
  }, [isBreak, onStop, onComplete]);

  // Timer logic
  useEffect(() => {
    let interval: NodeJS.Timeout;

    if (!isPaused && !waitingForUserInput) {
      interval = setInterval(() => {
        if (seconds > 0) {
          setSeconds(seconds - 1);
        } else if (minutes > 0) {
          setMinutes(minutes - 1);
          setSeconds(59);
        } else {
          // Timer finished
          if (isBreak) {
            setWaitingForUserInput(true);
            startShakeAnimation();
            showRepeatToast();
          } else {
            setIsBreak(true);
            setMinutes(breakMinutes);
            startShakeAnimation();
            toast(
              <div className="flex items-center justify-center gap-3 min-w-[300px]">
                <div className="bg-brand-green/20 p-2 rounded-full">
                  <FaCoffee className="text-brand-green text-lg" />
                </div>
                <span className="text-brand-green-dark font-medium">
                  Break time! Take a moment to relax.
                </span>
              </div>,
              {
                duration: 4000,
                position: "top-center",
                style: getToastStyle(),
              }
            );
          }
        }
      }, 1000);
    }

    return () => clearInterval(interval);
  }, [
    minutes,
    seconds,
    isPaused,
    isBreak,
    breakMinutes,
    waitingForUserInput,
    showRepeatToast,
  ]);

  // Add shrink effect after 3 seconds
  useEffect(() => {
    const shrinkTimer = setTimeout(() => {
      setIsShrunken(true);
    }, 3000);

    return () => clearTimeout(shrinkTimer);
  }, []);

  return (
    <div
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      className="absolute right-0 top-0"
      style={{
        transform: `scale(${isShrunken && !isHovered ? 0.65 : 0.8})`,
        animation: isShaking
          ? "shake 1s cubic-bezier(.36,.07,.19,.97) both"
          : "none",
        transition: "all 0.3s ease-in-out",
      }}
    >
      <style>
        {`
          @keyframes shake {
            10%, 90% { transform: translate3d(-1px, 0, 0); }
            20%, 80% { transform: translate3d(2px, 0, 0); }
            30%, 50%, 70% { transform: translate3d(-4px, 0, 0); }
            40%, 60% { transform: translate3d(4px, 0, 0); }
          }
        `}
      </style>
      <div
        className={`px-6 py-3 rounded-xl shadow-lg flex items-center space-x-4 ${isBreak ? "bg-brand-cream" : "bg-brand-green-mid"
          } backdrop-blur-sm relative`}
      >
        <div
          className={`text-2xl font-bold min-w-[100px] text-center ${isBreak ? "text-brand-green-dark" : "text-brand-cream"
            }`}
        >
          {String(minutes).padStart(2, "0")}:{String(seconds).padStart(2, "0")}
        </div>

        <div className="flex items-center space-x-2">
          <button
            onClick={handlePause}
            className={`p-2 rounded-full transition-colors cursor-pointer ${isBreak
                ? "hover:bg-brand-green-dark/10"
                : "hover:bg-brand-cream/10"
              }`}
          >
            {isPaused ? (
              <FaPlay
                className={
                  isBreak ? "text-brand-green-dark" : "text-brand-cream"
                }
              />
            ) : (
              <FaPause
                className={
                  isBreak ? "text-brand-green-dark" : "text-brand-cream"
                }
              />
            )}
          </button>

          <button
            onClick={handleStop}
            className={`p-2 rounded-full transition-colors cursor-pointer ${isBreak
                ? "hover:bg-brand-green-dark/10"
                : "hover:bg-brand-cream/10"
              }`}
          >
            <FaStop
              className={isBreak ? "text-brand-green-dark" : "text-brand-cream"}
            />
          </button>
        </div>

        <div
          className={`text-sm ${isBreak ? "text-brand-green-dark/80" : "text-brand-cream/80"
            }`}
        >
          {isBreak ? "Break Time" : "Focus Time"}
        </div>
      </div>
    </div>
  );
};

export default Timer;
