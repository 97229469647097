export const chatService = {
	getChatsByAssistant: async (
		token: string | null,
		assistantId: string | undefined,
	) => {
		if (!token || !assistantId) {
			return;
		}
		const response = await fetch(
			`${process.env.REACT_APP_API_BASE_URL}/api/chats/get-chats-for-assistant/${assistantId}`,
			{
				method: "GET",
				headers: {
					"Content-Type": "application/json",
					Authorization: `Bearer ${token}`,
				},
			},
		);
		const data = await response.json();
		return data;
	},
	getChatsByFolder: async (
		token: string | null,
		folderId: string | undefined,
	) => {
		if (!token || !folderId) {
			return;
		}
		const response = await fetch(
			`${process.env.REACT_APP_API_BASE_URL}/api/chats/get-chats-by-folder/${folderId}`,
			{
				method: "GET",
				headers: {
					"Content-Type": "application/json",
					Authorization: `Bearer ${token}`,
				},
			},
		);
		const data = await response.json();
		return data;
	},
	getFoldersByAssistant: async (
		token: string | null,
		assistantId: string | undefined,
	) => {
		if (!token || !assistantId) {
			return;
		}
		const response = await fetch(
			`${process.env.REACT_APP_API_BASE_URL}/api/chats/get-folders-for-assistant/${assistantId}`,
			{
				method: "GET",
				headers: {
					"Content-Type": "application/json",
					Authorization: `Bearer ${token}`,
				},
			},
		);
		const data = await response.json();
		return data;
	},

	getChatById: async (token: string | null, chatId: string | undefined) => {
		if (!token || !chatId) {
			return;
		}
		const response = await fetch(
			`${process.env.REACT_APP_API_BASE_URL}/api/chats/get-chat/${chatId}`,
			{
				method: "GET",
				headers: {
					"Content-Type": "application/json",
					Authorization: `Bearer ${token}`,
				},
			},
		);
		const data = await response.json();
		return data;
	},
	getFolderById: async (token: string | null, folderId: string | undefined) => {
		if (!token || !folderId) {
			return;
		}
		const response = await fetch(
			`${process.env.REACT_APP_API_BASE_URL}/api/chats/get-folder/${folderId}`,
			{
				method: "GET",
				headers: {
					"Content-Type": "application/json",
					Authorization: `Bearer ${token}`,
				},
			},
		);
		const data = await response.json();
		return data;
	},

	createChat: async (token: string | null, assistantId: string | undefined, title?: string) => {
		if (!token || !assistantId) {
			return;
		}
		const response = await fetch(
			`${process.env.REACT_APP_API_BASE_URL}/api/chats/create-chat`,
			{
				method: "POST",
				headers: {
					"Content-Type": "application/json",
					Authorization: `Bearer ${token}`,
				},
				body: JSON.stringify({ assistantId, title }),
			},
		);
		const data = await response.json();
		return data;
	},

	sendMessage: async (
		token: string | null,
		prompt: string,
		chatId: string,
		room: string,
	) => {
		if (!token) {
			return;
		}

		console.log({ room });
		const response = await fetch(
			`${process.env.REACT_APP_API_BASE_URL}/api/chats/new-question/${chatId}`,
			{
				method: "POST",
				headers: {
					"Content-Type": "application/json",
					Authorization: `Bearer ${token}`,
				},
				body: JSON.stringify({ prompt, room }),
			},
		);
		const data = await response.json();
		return data;
	},

	updateChatName: async (
		token: string | null,
		title: string,
		chatId: string,
	) => {
		if (!token) {
			return;
		}
		const response = await fetch(
			`${process.env.REACT_APP_API_BASE_URL}/api/chats/update-chat-name/${chatId}`,
			{
				method: "PUT",
				headers: {
					"Content-Type": "application/json",
					Authorization: `Bearer ${token}`,
				},
				body: JSON.stringify({ title }),
			},
		);
		const data = await response.json();
		return data;
	},
	updateFolderName: async (
		token: string | null,
		title: string,
		folderId: string,
	) => {
		if (!token) {
			return;
		}
		const response = await fetch(
			`${process.env.REACT_APP_API_BASE_URL}/api/chats/update-folder-name/${folderId}`,
			{
				method: "PUT",
				headers: {
					"Content-Type": "application/json",
					Authorization: `Bearer ${token}`,
				},
				body: JSON.stringify({ title }),
			},
		);
		const data = await response.json();
		return data;
	},
	deleteChat: async (token: string | null, chatId: string) => {
		if (!token) {
			return;
		}
		const response = await fetch(
			`${process.env.REACT_APP_API_BASE_URL}/api/chats/delete-chat/${chatId}`,
			{
				method: "DELETE",
				headers: {
					"Content-Type": "application/json",
					Authorization: `Bearer ${token}`,
				},
			},
		);
		const data = await response.json();
		return data;
	},
	deleteFolder: async (token: string | null, folderId: string) => {
		if (!token) {
			return;
		}
		const response = await fetch(
			`${process.env.REACT_APP_API_BASE_URL}/api/chats/delete-folder/${folderId}`,
			{
				method: "DELETE",
				headers: {
					"Content-Type": "application/json",
					Authorization: `Bearer ${token}`,
				},
			},
		);
		const data = await response.json();
		return data;
	},
	moveChats: async (token: string | null, folderId: string, chatId: string) => {
		if (!token || !chatId) {
			return;
		}
		const response = await fetch(
			`${process.env.REACT_APP_API_BASE_URL}/api/chats/move-chats/`,
			{
				method: "PUT",
				headers: {
					"Content-Type": "application/json",
					Authorization: `Bearer ${token}`,
				},
				body: JSON.stringify({ folderId, chatId }),
			},
		);
		const data = await response.json();
		return data;
	},
};
