import React, { useEffect, useState, useCallback } from "react";
import { useTheme } from "./ThemeContext";
import FolderIcon from "@mui/icons-material/Folder";
import EditIcon from "@mui/icons-material/Edit";
import { service } from "../../../services";
import { useAuth } from "@clerk/clerk-react";
import CloseIcon from "@mui/icons-material/Close";
import toast from "react-hot-toast";
import ChatPreview from "./ChatPreview";
import { useLocation, useNavigate } from "react-router-dom";
import IconWhite from "../../Icons/IconWhite";
import { useChat } from "../../../context/ChatContext";
import { useDrop } from "react-dnd";
import { motion, AnimatePresence } from "framer-motion";
import { useFolder } from "../../../context/FolderContext";

const FolderPreview: React.FC<any> = ({ folder }) => {
  const { theme } = useTheme();
  const [isEditingTitle, setIsEditingTitle] = useState(false);
  const [chats, setChats] = useState([]);
  const [editedTitle, setEditedTitle] = useState(folder.title);
  const { getToken } = useAuth();
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const { createChat, moveChat, getAllChats } = useChat();
  const { folderChats, getAllFolders } = useFolder();

  const navigate = useNavigate();
  const location = useLocation();

  // Droppable area setup
  const [{ canDrop, isOver }, drop] = useDrop({
    accept: "CHAT",
    drop: async (item: { id: string }) => {
      await moveChat(item.id, folder._id);
      fetchChats(folder._id); // Refresh chats after moving
    },
    collect: (monitor) => ({
      isOver: monitor.isOver(),
      canDrop: monitor.canDrop(),
    }),
  });

  // Memoised fetchChats to avoid unnecessary re-renders
  const fetchChats = useCallback(async (folderId: string) => {
    const token = await getToken();
    try {
      const response = await service.chatService.getFolderById(token, folderId);
      setChats(response.chats); // Set fetched chats
      console.log("Updated chats:", response.chats); // Debugging: Check fetched chats
    } catch (error) {
      console.error("Error fetching chats:", error);
    }
  }, [getToken]); // Memoise based on getToken

  // Effect to fetch chats based on location search params
  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const folderIdInParams = searchParams.get("folder");

    if (folderIdInParams === folder._id) {
      setIsDropdownOpen(true);
      fetchChats(folder._id);
    }
  }, [location.search, folder._id, fetchChats]);

  // Effect to fetch chats when folderChats or dropdown state changes
  useEffect(() => {
    if (isDropdownOpen) {
      fetchChats(folder._id);
    }
  }, [folderChats, isDropdownOpen, folder._id, fetchChats]);

  // Add chat to folder and fetch updated chats
  const handleCreateChatClick = async (event: React.MouseEvent) => {
    event.stopPropagation();

    // Create a new chat inside the folder
    const newChat = await createChat(folder._id);

    // Set the folder as open (dropdown)
    setIsDropdownOpen(true);

    // Update the folder state and fetch chats for the folder
    fetchChats(folder._id);

    // Navigate to the new chat inside the folder
    const currentPath = window.location.pathname;
    const searchParams = new URLSearchParams(window.location.search);

    // Make sure the folder is set in the query parameters
    searchParams.set("folder", folder._id);

    // Optionally, if you want to navigate to the specific chat URL, append the chat ID to the search
    searchParams.set("chat", newChat._id);

    // Navigate to update the URL
    navigate(`${currentPath}?${searchParams.toString()}`);
  };

  // Edit folder title logic
  const handleEditClick = (event: React.MouseEvent) => {
    event.stopPropagation();
    setIsEditingTitle(true);
  };

  // Create a new function to handle the save logic
  const handleSaveTitle = async () => {
    const lengthOfWords = editedTitle?.split(" ").filter(Boolean).length;
    if (lengthOfWords < 5) {
      await service.chatService.updateFolderName(
        await getToken(),
        editedTitle,
        folder._id
      );
      setIsEditingTitle(false);
      folder.title = editedTitle;
      getAllFolders(); // Refresh folders after title change
    } else {
      toast.error("Please Enter a title with less than five words");
    }
  };

  // Update the keyDown handler
  const handleKeyDown = async (event: React.KeyboardEvent) => {
    if (event.key === "Enter") {
      event.preventDefault();
      await handleSaveTitle();
    }
  };

  // Handle folder deletion
  const handleDeleteFolder = async (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.stopPropagation();
    const isConfirmed = window.confirm(
      "Deleting this folder is permanent and it can never be recovered. This will delete all chats contained in the folder. Click OK to delete or Cancel if you do not want to delete it."
    );

    if (isConfirmed) {
      await service.chatService.deleteFolder(await getToken(), folder._id);
      getAllFolders();
      getAllChats(); // Refresh all chats if necessary
    }
  };

  // Handle folder click to toggle dropdown
  const handleFolderClick = async () => {
    const newIsDropdownOpen = !isDropdownOpen;
    setIsDropdownOpen(newIsDropdownOpen);

    const currentPath = window.location.pathname;
    const searchParams = new URLSearchParams(window.location.search);

    if (newIsDropdownOpen) {
      fetchChats(folder._id);
      searchParams.set("folder", folder._id);
    } else {
      searchParams.delete("folder");
    }

    navigate(`${currentPath}?${searchParams.toString()}`);
  };

  // String truncation utility for folder title
  const truncatedString = (text: string) => {
    if (text.length <= 15) {
      return <span>{text}</span>;
    } else {
      const truncatedText = text.substring(0, 20) + "...";
      return <span>{truncatedText}</span>;
    }
  };

  const containerVariants = {
    hidden: { height: 0, opacity: 0 },
    visible: {
      height: "auto",
      opacity: 1,
      transition: {
        when: "beforeChildren",
        staggerChildren: 0.1,
      },
    },
  };

  const itemVariants = {
    hidden: { opacity: 0, y: -50 },
    visible: {
      opacity: 1,
      y: 0,
      transition: {
        duration: 0.5,
      },
    },
  };

  return (
    <div
      ref={drop}
      className={`w-full folder-preview ${canDrop && isOver
        ? "bg-brand-cream bg-opacity-50 border-2 border-dashed border-brand-green rounded-3xl mb-4"
        : ""
        }`}
    >
      {!isEditingTitle ? (
        <div
          className={`flex items-center justify-between py-2 px-4 mb-4 rounded-[100px] bg-brand-cream opacity-90 text-[14px] font-bold text-brand-green hover:border-brand-dark-green hover:text-brand-green-dark  w-full hover:cursor-pointer transform transition-transform duration-300 ease-in-out hover:scale-105 step-9 hover:bg-brand-logo ${folder._id === folder.selectedFolder?._id
            ? "border-brand-green border-[1px] !text-brand-green-dark bg-brand-logo"
            : ""
            }`}
          onClick={handleFolderClick}
        >
          <div className="flex items-center">
            <FolderIcon htmlColor="#003b1f" />
            <span className="flex-1 ml-4">{truncatedString(folder.title)}</span>
          </div>
          <div className="flex">
            <button
              className="flex items-center justify-center ml-4 step-7"
              onClick={handleCreateChatClick}
            >
              <div className="w-6 h-4">
                <IconWhite

                  name="newChat"
                  className="mr-2 -mt-[2px]"
                />
              </div>
            </button>
            <button
              onClick={handleEditClick}
              className="flex items-center justify-center ml-1 step-8"
            >
              <EditIcon className="text-brand-green" />
            </button>
            <button className="close-icon step-9" onClick={handleDeleteFolder}>
              <CloseIcon sx={{ color: "#003b1f", marginLeft: "0px" }} />
            </button>
          </div>
        </div>
      ) : (
        <div
          className={`flex items-center justify-between py-2 px-4 mb-4 rounded-full border-2 w-full ${theme === "dark"
            ? "border-brand-cream text-brand-cream"
            : "border-brand-cream text-brand-cream"
            } hover:border-brand-cream hover:text-brand-cream active:bg-brand-orange active:text-cream ${isEditingTitle ? "h-12 text-brand-cream" : "h-12 text-brand-cream"
            }`}
        >
          <input
            type="text"
            value={editedTitle}
            onChange={(e) => setEditedTitle(e.target.value)}
            onKeyDown={handleKeyDown}
            autoFocus
            onBlur={handleSaveTitle}
            className="bg-transparent border-none outline-none w-full h-full px-4 py-2 text-brand-cream"
            style={{ height: "90%", fontSize: "14px" }}
          />
        </div>
      )}

      <AnimatePresence initial={false}>
        {isDropdownOpen && (
          <motion.div
            initial="hidden"
            animate="visible"
            exit="hidden"
            variants={containerVariants}
            className="pl-4 overflow-hidden"
          >
            <div className="w-full h-full border-l pl-2 border-brand-cream/50 ">
              {chats.map((chat: any) => (
                <motion.div key={chat._id} variants={itemVariants}>
                  <ChatPreview chat={chat} />
                </motion.div>
              ))}
            </div>
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
};

export default FolderPreview;
